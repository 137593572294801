@import url('https://fonts.googleapis.com/css?family=Patrick+Hand+SC|Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.cursFont {
  font-family: 'Dancing Script', cursive;
  font-size: 28px;
}

.nav-link{
  padding: 5px 5px !important;
}

.nav-link:focus,
.nav-link:hover {
  background-color: var(--primary-bg-color);
  opacity: 0.7;
  border-radius: 5px;
  color: white;
  padding: 5px 5px !important;
}

.nav-link-active {
  color: var(--primary-bg-color);
  opacity: 0.7;
  border-bottom: 2px solid var(--primary-bg-color);
}

::-webkit-scrollbar {
  width: 10px !important;
  height: 10px !important;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--primary-bg-color) !important;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4 !important;
  border-radius: 100px !important;
}

::-webkit-scrollbar-thumb {
  background-color: #888 !important; 
  border-radius: 100px !important;
}

::-webkit-scrollbar:horizontal {
  height: 10px !important;
  background-color: #888 !important;
  border-radius: 100px !important;
}

::-webkit-scrollbar-track:horizontal {
  background-color: #e4e4e4 !important;
  border-radius: 100px !important;
}

::-webkit-scrollbar-thumb:horizontal:hover {
  background-color: var(--primary-bg-color) !important;
}

.plistdatepicker {
    height: 40px;
    width: 85px !important;
}

.DatePicker {
  z-index: 1 !important;
}

.header .dropdown-menu {
   margin-left: 0 !important; 
}

input[type=checkbox] {
  accent-color: var(--primary-bg-color) !important;
}

a {
  color: var(--primary-bg-color) !important;
}

.TreeNode{
  display: flex;  
}

.displayName{
  cursor: pointer;
}

@keyframes fade {
  from {
    opacity: 0;
    visibility: hidden;
    transform: translateY(25%);
    transition: all 1s;
    }

  to {
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
  }
}

.FolderTree{
  max-height: 600px;
  overflow: auto;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.caretContainer{
margin-top: 3px;
}

.TreeNode > .CheckBox > .checkboxDOM{
  margin-right: 0;
  gap:4px;
}

.TreeNode{
  animation: fade 0.15s linear forwards;
  padding-inline: 5px;
}

.TreeNodeToolBar{
  display: flex;
  margin-top: 3px;
  margin-left: 3px;
  display: none;
}

.centerImg{
  width: auto;
  height: auto;
}

.react-pdf__Page__canvas{
  margin: 0 auto;
  border: 1px solid rgb(209, 208, 208);
}

.modalGrid{
  display: grid;
  gap: 5px;
  grid-template-columns: 40% 59%;
}

.pagination{
  margin-bottom: 0px !important;
}

.rc-tree-node-selected {
  background-color: white !important; 
  box-shadow: 0 0 0 0 !important;
  opacity: 1 !important;
  color: var(--primary-bg-color);
}

.rs-btn-primary {
  background-color: var(--primary-bg-color) !important;
}

.rs-calendar-table-cell-selected .rs-calendar-table-cell-content{
  background-color: var(--primary-bg-color) !important;
}

.rs-calendar-table-cell-is-today .rs-calendar-table-cell-content{
  box-shadow: inset 0 0 0 1px var(--primary-bg-color) !important;
}

.rs-calendar-table-cell-in-range:before{
  background-color: var(--primary-bg-color-25) !important;
}

.rs-calendar-table-cell-content:hover{
  background-color: var(--primary-bg-color-50) !important;
  color: var(--primary-bg-color) !important;
}

.rs-picker-menu .rs-calendar .rs-calendar-table-cell:hover .rs-calendar-table-cell-content
{
  background-color: var(--primary-bg-color-25) !important;
  color: var(--primary-bg-color) !important;
}

.rs-picker-toggle-active,
.rs-picker.rs-picker-focused{
box-shadow: 0 0 0 0 !important;
}

.rs-picker-default .rs-picker-toggle.rs-btn {
  padding-bottom: 7px !important;
  padding-top: 6px !important;
}

.rs-picker-has-value .rs-btn .rs-picker-toggle-value,
.rs-picker-has-value .rs-picker-toggle .rs-picker-toggle-value{
  color: black !important;
}

.rs-picker-default .rs-picker-toggle{
  border: 2px solid #e9edf4 !important;
  border-radius: 3px !important;
}

.rs-picker-toggle-read-only{
  opacity: 1 !important;
}

.rs-picker-default .rs-btn,
.rs-picker-default .rs-picker-toggle{
  transition: none !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:hover {
  border: 2px solid #e9edf4 !important;
  border-radius: 3px !important;
}

.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:active,
.rs-picker-default:not(.rs-picker-disabled) .rs-picker-toggle:focus-within {
  border: 2px solid #e9edf4 !important;
  border-color: var(--primary-bg-color) !important;
  border-radius: 3px !important;
}

.rs-picker-toggle:active{
    border: 2px solid #e9edf4 !important;
      border-color: var(--primary-bg-color) !important;
      border-radius: 3px !important;
}

.rc-tree-treenode{
  font-size: 12px;
}

.BaseTable__header-row{
  background-color: var(--primary-bg-color) !important;
  border-left: 1px solid rgba(0, 0, 0, .12) !important;
  color: white;
  font-weight: 500 !important;
}

.row-selected{
  background-color: var(--primary-bg-color-50) !important;
}

.BaseTable__row-cell, .BaseTable__header-cell{
  border-left: 1px solid rgba(0,0,0,.12) !important;
}

.BaseTable__row--customized:hover {
  background-color: var(--primary-bg-color-25) !important;
}

.BaseTable__header-cell--sortable:hover{
  background-color: #f3f3f300  !important;
}

.BaseTable {
  font-size: 12px !important;
  color:black !important;
}

.virtualHight-45{
  max-height: 45vh !important;
}
