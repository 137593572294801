/*Color variables*/
/*Gradient variables*/
/*white variables*/
/*black variables*/
/*shadow variables*/
/*Dark Theme Variables*/
/*Transparent variables*/
body.dark-mode {
    color: #dedefd;
    background-color: #1a1a3c;
}

body.dark-mode *::-webkit-scrollbar-thumb,
body.dark-mode *:hover::-webkit-scrollbar-thumb {
    background: #1a1a3c;
}

.dark-mode {
    /*------ Accordion -------*/
    /* #accordion rotate icon option */
    /*----- Avatars -----*/
    /*-----Badges-----*/
    /*------ Breadcrumb ------*/
    /*------ Card -------*/
    /*------ Default Card Values -------*/
    /*Card maps*/
    /*------ Card -------*/
    /*------ Default Card Values -------*/
    /*Card maps*/
    /*------ Carousel -------*/
    /*------ Carousel -------*/
    /*------Drop Downs-------*/
    /*-----List Of Dropdwons-----*/
    /*------- Forms -------*/
    /*------ Modal -------*/
    /*------ Navigation -------*/
    /* #bs-collapse icon scale option */
    /*--------panel----------*/
    /*-----progress-----*/
    /*------ Tables -----*/
    /*---- Tags-----*/
    /*---------Thumbnails----------*/
    /*----- Custom control -----*/
    /*----- Global Loader -----*/
    /* --------Added--------- css*/
    /* line 77, C:/wamp/www/github/addSlider/src/partials/_addSlider.scss */
    /*********************IE***************************/
    /*--- Offcanvas ---*/
    /*--Toast ---*/
    /*-----Gallery-----*/
    /*-----Lists-----*/
    /*------ Media object ------*/
    /*--scrollspy ---*/
    /*-----Pricing tables-----*/
    /*------ Pricing Styles ---------*/
    /*-- rating--*/
    /***** time-line*****/
    /*---- Time line -----*/
    /*----Timeline---*/
    /* ######## LAYOUT-STYLES ######## */
    /*Logo-center header */
    /* Desktop Search Bar */
    /*==============================================================================
                                Start Mobile CSS
  ===============================================================================*/
    /* ================== Mobile Menu Change Brake Point ================== */
    /* Extra @Media Query*/
    /*------ Icons List ------*/
    /* ######## LIB-STYLES ######## */
    /*----- Date Picker ----*/
    /*-----selectize ------*/
    /* ######## SIDEMENU-STYLES ######## */
    /*-- Subslide ---*/
    /*-- Subslide2 ---*/
    /* ######## TEMP-STYLES ######## */
    /*------ Charts styles ------*/
    /*----chart-drop-shadow----*/
    /*----chart-drop-shadow----*/
    /* ###### Chat  ###### */
    /* ###### Chat  ###### */
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*----- Range slider -------*/
    /*--- WIZARD ELEMENTS ---*/
    /****** EQUAL COLUMN WIDTH STEP INDICATOR *****/
    /***** CUSTOM STYLES *****/
    /*--- WIZARD ELEMENTS ---*/
    /* ######## UTILITIES-STYLES ######## */
    /*--- gradient-backgrounds --*/
    /*------ Background colors -------*/
    /*--bg-transparents--*/
    /*--Box-shadow--*/
    /*------- Alignments & values-------*/
    /*----- Typography ------*/
    /*--text-shadow--*/
}

.dark-mode table.dataTable>tbody>tr.child ul.dtr-details>li {
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .dtr-bs-modal .dtr-details {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .dtr-bs-modal .dtr-details tr td:first-child {
    border-right: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode caption {
    color: #505662;
}

.dark-mode hr {
    border-top-color: rgba(255, 255, 255, 0.2);
    background-color: transparent;
}

.dark-mode mark,
.dark-mode .mark {
    background-color: #fcf8e3;
}

.dark-mode .list-unstyled li {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode kbd {
    color: #fff;
    background-color: #343a40;
}

.dark-mode pre {
    color: #dedefd;
}

@media print {

    .dark-mode pre,
    .dark-mode blockquote {
        border-color: #adb5bd;
    }

    .dark-mode .badge {
        border-color: #000;
    }

    .dark-mode .table td,
    .dark-mode .text-wrap table td,
    .dark-mode .table th,
    .dark-mode .text-wrap table th {
        background-color: #2a2a4a !important;
    }

    .dark-mode .table-bordered th,
    .dark-mode .text-wrap table th,
    .dark-mode .table-bordered td,
    .dark-mode .text-wrap table td {
        border-color: rgba(255, 255, 255, 0.1) !important;
    }
}

.dark-mode body *::-webkit-scrollbar-thumb,
.dark-mode body *:hover::-webkit-scrollbar-thumb {
    background: #2a2a4a;
}

.dark-mode code {
    background: #2a2a4a;
    border-color: transparent;
    color: #ec82ef;
}

.dark-mode pre {
    color: #343a40;
    background-color: #2a2a4a;
    text-shadow: 0 1px #2a2a4a;
}

.dark-mode .section-nav {
    background-color: #f8f9fa;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .accordionjs .acc_section {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .accordionjs .acc_section .acc_head h3:before {
    color: #dedefd;
}

.dark-mode .accordionjs .acc_section .acc-header {
    background: #2a2a4a;
}

.dark-mode .accordionjs .acc_section .acc_head {
    background: #2a2a4a;
}

.dark-mode .accordionjs .acc_section.acc_active>.acc_head {
    color: #dedefd !important;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .accordion-item {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .accordion-button {
    color: #dedefd;
}

.dark-mode .accordion-button:focus {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .accordion-button:not(.collapsed) {
    color: var(--primary-bg-color);
    background: #22223a;
}

.dark-mode #accordion .panel-default>.panel-heading {
    border: 0px solid rgba(255, 255, 255, 0.1);
}

.dark-mode #accordion .panel-default .collapsing .panel-body {
    border-top: 0px solid transparent;
}

.dark-mode .avatar-status {
    border: 2px solid #2a2a4a;
}

.dark-mode .avatar-list-stacked .avatar {
    box-shadow: 0 0 0 2px #2a2a4a;
}

.dark-mode .btn-custom {
    background: #2a2a4a;
    color: #9595b5;
}

.dark-mode .blockquote-footer {
    color: #9595b5;
}

.dark-mode blockquote {
    color: #9595b5;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .blockquote-reverse {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .breadcrumb-item a {
    color: #9595b5;
}

.dark-mode .breadcrumb-item+.breadcrumb-item::before {
    color: #9595b5;
}

.dark-mode .breadcrumb1 {
    background-color: #1a1a3c;
}

.dark-mode .breadcrumb-item1 a:hover {
    color: var(--primary-bg-color);
}

.dark-mode .breadcrumb-item1+.breadcrumb-item1::before {
    color: #5b5b62;
}

.dark-mode .breadcrumb-item1.active {
    color: #9595b5;
}

.dark-mode .btn-default {
    color: #282f53;
    background: #e9e9f1;
    border-color: #e9e9f1;
}

.dark-mode .btn-default:hover {
    color: #282f53;
    background-color: #e3e3ef;
    border-color: #e3e3ef;
}

.dark-mode .btn-default:focus,
.dark-mode .btn-default.focus {
    box-shadow: 0 0 0 2px #e9e9f1;
}

.dark-mode .btn-default.disabled,
.dark-mode .btn-default:disabled {
    color: #282f53;
    background-color: #e9e9f1;
    border-color: #e9e9f1;
}

.dark-mode .btn-default:not(:disabled):not(.disabled):active,
.dark-mode .btn-default:not(:disabled):not(.disabled).active {
    color: #282f53;
    background-color: #e9e9f1;
    border-color: #e9e9f1;
}

.dark-mode .btn-light {
    color: #dedefd;
    background-color: #39395c;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-light:hover {
    color: #dedefd;
    background-color: #2f2f4b;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-light:focus,
.dark-mode .btn-light.focus {
    box-shadow: 0 0 0 2px rgba(248, 249, 250, 0.1);
}

.dark-mode .btn-light.disabled,
.dark-mode .btn-light:disabled {
    color: #495057;
    background-color: #f8f9fa;
    border-color: #f8f9fa;
}

.dark-mode .btn-light:not(:disabled):not(.disabled):active,
.dark-mode .btn-light:not(:disabled):not(.disabled).active {
    color: #495057;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-outline-default {
    color: #dedefd;
    background: transparent;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-outline-default:hover {
    color: #282f53;
    background: #e9e9f1;
}

.dark-mode .btn-white {
    color: #dedefd;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-white:hover {
    color: #dedefd;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-white:focus,
.dark-mode .btn-white.focus {
    box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.5);
}

.dark-mode .btn-white.disabled,
.dark-mode .btn-white:disabled {
    color: #495057;
    background-color: #2a2a4a;
    border-color: #fff;
}

.dark-mode .btn-white:not(:disabled):not(.disabled):active,
.dark-mode .btn-white:not(:disabled):not(.disabled).active {
    color: #495057;
    background-color: #545478;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .show>.btn-white.dropdown-toggle {
    color: #495057;
    background-color: #545478;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .btn-check:active+.btn-outline-primary,
.dark-mode .btn-check:checked+.btn-outline-primary {
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.dark-mode .btn-outline-primary {
    border-color: var(--primary-bg-color);
}

.dark-mode .btn-outline-primary.active,
.dark-mode .btn-outline-primary.dropdown-toggle.show,
.dark-mode .btn-outline-primary:active {
    background-color: var(--primary-bg-color);
    border-color: var(--primary-bg-color);
}

.dark-mode .card {
    background-color: #2a2a4a;
    border: inherit !important;
    box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
}

.dark-mode .card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top-color: rgba(255, 255, 255, 0.1);
    color: #6e7687;
}

.dark-mode .card .card {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-body+.card-body {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-title small {
    color: #9aa0ac;
}

.dark-mode .card-subtitle {
    color: #9aa0ac;
}

.dark-mode .card-body+.card-table {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-body+.card-list-group {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-options {
    color: #9aa0ac;
}

.dark-mode .card-options a:not(.btn) {
    color: #9595b5;
}

.dark-mode .card-options a:not(.btn):hover {
    color: #6e7687;
}

.dark-mode .card-map {
    background: #e9ecef;
}

.dark-mode .card .box h2 {
    color: #262626;
}

.dark-mode .card .box h2 span {
    color: #fff;
}

.dark-mode .card .box p {
    color: #262626;
}

.dark-mode .card-footer {
    background-color: rgba(0, 0, 0, 0.03);
    border-top-color: rgba(255, 255, 255, 0.1);
    color: #727293;
}

.dark-mode .card .card {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 10px #1c1c2a;
}

.dark-mode .card-body+.card-body {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-title small {
    color: #9aa0ac;
}

.dark-mode .card-subtitle {
    color: #9aa0ac;
}

.dark-mode .card-body+.card-table {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-body+.card-list-group {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-options {
    color: #9aa0ac;
}

.dark-mode .card-options a:not(.btn) {
    color: #9595b5;
}

.dark-mode .card-options a:not(.btn):hover {
    color: #6e7687;
}

.dark-mode .card-map {
    background: #e9ecef;
}

.dark-mode .card .box h2 {
    color: #262626;
}

.dark-mode .card .box h2 span {
    color: #fff;
}

.dark-mode .card .box p {
    color: #262626;
}

.dark-mode .cardheader-tabs .card-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .carousel-control-prev,
.dark-mode .carousel-control-next {
    color: #fff;
}

.dark-mode .carousel-control-prev:hover,
.dark-mode .carousel-control-prev:focus {
    color: #fff;
}

.dark-mode .carousel-control-next:hover,
.dark-mode .carousel-control-next:focus {
    color: #fff;
}

.dark-mode .carousel-indicators li,
.dark-mode .carousel-indicators1 li,
.dark-mode .carousel-indicators2 li,
.dark-mode .carousel-indicators3 li,
.dark-mode .carousel-indicators4 li,
.dark-mode .carousel-indicators5 li {
    background-color: rgba(255, 255, 255, 0.5);
}

.dark-mode .carousel-indicators .active,
.dark-mode .carousel-indicators1 .active,
.dark-mode .carousel-indicators2 .active,
.dark-mode .carousel-indicators3 .active,
.dark-mode .carousel-indicators4 .active,
.dark-mode .carousel-indicators5 .active {
    background-color: #fff;
}

.dark-mode .carousel-caption {
    color: #fff;
}

.dark-mode .carousel-item-background {
    background: rgba(0, 0, 0, 0.5);
}

.dark-mode .carousel-control-prev,
.dark-mode .carousel-control-next {
    color: #fff;
}

.dark-mode .carousel-control-prev:hover,
.dark-mode .carousel-control-prev:focus {
    color: #fff;
}

.dark-mode .carousel-control-next:hover,
.dark-mode .carousel-control-next:focus {
    color: #fff;
}

.dark-mode .carousel-indicators li,
.dark-mode .carousel-indicators1 li,
.dark-mode .carousel-indicators2 li,
.dark-mode .carousel-indicators3 li,
.dark-mode .carousel-indicators4 li,
.dark-mode .carousel-indicators5 li {
    background-color: rgba(255, 255, 255, 0.5);
}

.dark-mode .carousel-indicators .active,
.dark-mode .carousel-indicators1 .active,
.dark-mode .carousel-indicators2 .active,
.dark-mode .carousel-indicators3 .active,
.dark-mode .carousel-indicators4 .active,
.dark-mode .carousel-indicators5 .active {
    background-color: #fff;
}

.dark-mode .carousel-caption {
    color: #fff;
}

.dark-mode .carousel-item-background {
    background: rgba(0, 0, 0, 0.5);
}

.dark-mode .dropdown-menu {
    color: #9595b5;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .dropdown-divider {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .drop-heading {
    color: #dedefd;
}

.dark-mode .dropdown-item {
    color: #9595b5;
}

.dark-mode .dropdown-item:hover,
.dark-mode .dropdown-item:focus,
.dark-mode .dropdown-item.active,
.dark-mode .dropdown-item:active {
    background-color: #303052;
}

.dark-mode .dropdown-item.disabled,
.dark-mode .dropdown-item:disabled {
    color: #fff;
}

.dark-mode .dropdown-menu.show {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
}

.dark-mode .dropdown-header {
    color: #9595b5;
}

.dark-mode .dropdown-item-text {
    color: #dedefd;
}

.dark-mode btn.dropdown-toggle~.dropdown-menu,
.dark-mode ul.dropdown-menu li.dropdown ul.dropdown-menu {
    background-color: #f4f4f4 !important;
    background-color: white !important;
    border: 0 solid #4285f4 !important;
    box-shadow: 0px 0px 3px rgba(25, 25, 25, 0.3) !important;
}

.dark-mode .dropdown-menu {
    background-color: #2a2a4a;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
}

.dark-mode .dropdown-menu>li>a {
    color: #dedefd;
}

.dark-mode .dropdown-menu>li>a:hover,
.dark-mode .dropdown-menu>li>a:focus {
    color: #9595b5;
    background-color: #2a2a4a;
}

.dark-mode .dropdown-menu .divider {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .dropdown-menu .dropdown-plus-title {
    color: #dedefd !important;
    border: 0 solid rgba(255, 255, 255, 0.1) !important;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .dropdown-menu-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .dropdown-menu-header label {
    color: #9595b5;
}

.dark-mode .dropdown-menu-arrow:before,
.dark-mode .dropdown-menu.header-search:before {
    background: #2a2a4a;
    border-top-color: rgba(255, 255, 255, 0.1);
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .dropdown-menu {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.dark-mode .dropdown-toggle .dropdown-label {
    background-color: #2a2a4a;
}

.dark-mode .form-control {
    color: #dedefd;
    background-color: #282848;
    border-color: rgba(255, 255, 255, 0.1) !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.dark-mode .form-control::-ms-expand {
    background-color: transparent;
}

.dark-mode .form-control:focus {
    color: #a9a9c1;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .form-control::placeholder {
    color: #626281 !important;
}

.dark-mode .form-control::-webkit-input-placeholder,
.dark-mode .form-control::-moz-placeholder,
.dark-mode .form-control:-ms-input-placeholder,
.dark-mode .form-control::-ms-input-placeholder {
    color: #fff !important;
}

.dark-mode .form-control:disabled,
.dark-mode .form-control[readonly] {
    background-color: #282848;
}

.dark-mode .form-control::-webkit-file-upload-button {
    color: #dedefd;
    background-color: #39395c;
}

.dark-mode .form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #2a2a4a;
}

.dark-mode .form-control-plaintext {
    color: #9595b5;
}

.dark-mode .form-check-input:disabled~.form-check-label {
    color: #505662;
}

.dark-mode .was-validated .custom-control-input:valid:focus~.custom-control-label::before,
.dark-mode .custom-control-input.is-valid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #2a2a4a, 0 0 0 2px rgba(9, 173, 149, 0.25);
}

.dark-mode .was-validated .form-control:invalid,
.dark-mode .form-control.is-invalid,
.dark-mode .was-validated .form-select:invalid,
.dark-mode .form-select.is-invalid {
    border-color: #dc3545;
    background-repeat: no-repeat;
}

.dark-mode .was-validated .form-control:invalid:focus,
.dark-mode .form-control.is-invalid:focus,
.dark-mode .was-validated .form-select:invalid:focus,
.dark-mode .form-select.is-invalid:focus {
    border-color: #dc3545;
}

.dark-mode .was-validated .form-check-input:invalid~.form-check-label,
.dark-mode .form-check-input.is-invalid~.form-check-label,
.dark-mode .was-validated .custom-control-input:invalid~.custom-control-label,
.dark-mode .custom-control-input.is-invalid~.custom-control-label {
    color: #dc3545;
}

.dark-mode .was-validated .custom-control-input:invalid~.custom-control-label::before,
.dark-mode .custom-control-input.is-invalid~.custom-control-label::before {
    background-color: #ec8080;
}

.dark-mode .was-validated .custom-control-input:invalid:checked~.custom-control-label::before,
.dark-mode .custom-control-input.is-invalid:checked~.custom-control-label::before {
    background-color: #e23e3d;
}

.dark-mode .was-validated .custom-control-input:invalid:focus~.custom-control-label::before,
.dark-mode .custom-control-input.is-invalid:focus~.custom-control-label::before {
    box-shadow: 0 0 0 1px #2a2a4a, 0 0 0 2px rgba(232, 38, 70, 0.25);
}

.dark-mode .form-control.header-search {
    background: #2a2a4a;
    border-color: rgba(225, 225, 225, 0.1);
    color: #fff;
}

.dark-mode .form-control.header-search::placeholder {
    color: #fff;
}

.dark-mode .form-control.header-search:hover,
.dark-mode .form-control.header-search:focus {
    border-color: rgba(225, 225, 225, 0.1);
}

.dark-mode .form-required {
    color: #dc3545;
}

.dark-mode .form-check-input:focus {
    border-color: var(--primary-bg-color);
}

.dark-mode .form-check-input {
    background: #41415c;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .input-group-text {
    color: #fff;
    background-color: var(--primary-bg-color);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .input-indec .input-group-btn>.btn {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .input-group-text {
    color: #fff;
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .input-indec .input-group-btn>.btn {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .modal-content {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 5px 20px rgba(0, 0, 0, 0.08);
}

.dark-mode .modal-backdrop {
    background-color: #000;
}

.dark-mode .modal-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .modal-footer {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .modal.effect-just-me .modal-content {
    background-color: #16192f;
    border-color: #4d4e50;
}

.dark-mode .modal.effect-just-me .btn-close {
    color: #fff;
}

.dark-mode .modal.effect-just-me .modal-header {
    background-color: transparent;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .modal.effect-just-me .modal-header h6 {
    color: #fff;
}

.dark-mode .modal.effect-just-me .modal-body {
    color: rgba(255, 255, 255, 0.8);
}

.dark-mode .modal.effect-just-me .modal-body h6 {
    color: #fff;
}

.dark-mode .modal.effect-just-me .modal-footer {
    background-color: transparent;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .nav-tabs {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .nav-pills .nav-link.active,
.dark-mode .nav-pills .show>.nav-link {
    color: #fff;
}

.dark-mode .nav.nav-pills.nav-stacked.labels-info p {
    color: #9d9f9e;
}

.dark-mode .nav.nav-pills .nav-item .nav-link-icon {
    color: #dedefd;
}

.dark-mode .nav1 {
    background: #1a1a3c;
}

.dark-mode .nav-item1:hover:not(.disabled),
.dark-mode .nav-item1.active {
    color: #fff;
}

.dark-mode .nav-item1 .nav-link {
    color: #dedefd;
}

.dark-mode .nav-item1 .nav-link.active {
    color: var(--primary-bg-color);
}

.dark-mode .nav-item1 .nav-link.disabled {
    color: #62628f;
}

.dark-mode .nav-tabs {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .nav-tabs .nav-item1.nav-link {
    border-color: transparent;
}

.dark-mode .nav-tabs .nav-item1 .nav-link {
    color: inherit;
    color: #9595b5;
    transition: 0.3s border-color;
}

.dark-mode .nav-tabs .nav-item1 .nav-link:hover:not(.disabled),
.dark-mode .nav-tabs .nav-item1 .nav-link.active {
    color: #fff;
}

.dark-mode .nav-tabs .nav-submenu .nav-item1 {
    color: #9aa0ac;
}

.dark-mode .nav-tabs .nav-submenu .nav-item1.active {
    color: #467fcf;
}

.dark-mode .nav-tabs .nav-submenu .nav-item1:hover {
    color: #6e7687;
    background: rgba(0, 0, 0, 0.024);
}

.dark-mode .nav-link {
    color: var(--primary-bg-color);
}

.dark-mode .nav-link.icon i {
    color: #dcdfed;
}

.dark-mode .nav-link.icon i::after {
    background: rgba(var(--primary-bg-color), 0);
}

.dark-mode .app-header .header-right-icons .nav-link.icon {
    color: #dcdfed !important;
}

.dark-mode .app-header .header-right-icons .nav-link.icon:hover {
    color: #dcdfed !important;
}

.dark-mode.header-light .app-header .header-right-icons .nav-link.icon {
    color: var(--primary-bg-color) !important;
}

.dark-mode .app-header .header-right-icons .nav-link.icon:hover {
    color: #dcdfed !important;
}

.dark-mode .nav-tabs {
    color: #000;
}

.dark-mode .nav-tabs .nav-link {
    color: inherit;
    color: #9595b5;
}

.dark-mode .nav-tabs .nav-link:hover:not(.disabled),
.dark-mode .nav-tabs .nav-link.active {
    color: #fff;
}

.dark-mode .nav-tabs .nav-link.disabled {
    color: #868e96;
    background-color: transparent;
    border-color: transparent;
}

.dark-mode .nav-tabs .nav-submenu {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    border-top: none;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.dark-mode .nav-tabs .nav-submenu .nav-item {
    color: #9aa0ac;
}

.dark-mode .nav-tabs .nav-submenu .nav-item:hover {
    color: #6e7687;
    text-decoration: none;
    background: rgba(0, 0, 0, 0.024);
}

.dark-mode .page-link {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    color: #9595b5;
}

.dark-mode .page-link:hover {
    background-color: #2a2a4a;
}

.dark-mode .page-item.active .page-link {
    color: #fff;
}

.dark-mode .page-item.disabled .page-link {
    color: #4f4f67;
    background-color: #252542;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .page-header {
    border-color: transparent;
}

.dark-mode .panel-title-landing {
    color: #1643a3 !important;
}

.dark-mode .panel-footer-landing {
    border: solid 2px #1643a3 !important;
    border-top: none !important;
    background: #f7f7f7;
}

.dark-mode .panel-footer {
    background-color: #2a2a4a;
    border-top-color: rgba(255, 255, 255, 0.1);
    border-left-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .panel-group .panel,
.dark-mode .panel-group1 .panel {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .panel-default>.panel-heading {
    background-color: #313152;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .panel-default>.panel-heading+.panel-collapse>.panel-body {
    border: 0px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .panel1 {
    border-color: #fff;
}

.dark-mode .panel1:last-child {
    border-bottom: none;
}

.dark-mode .panel-body1 {
    background: #2a2a4a;
}

.dark-mode .panel-group1 .panel-body {
    border: 0px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .panel-title1 a {
    color: #fff !important;
}

.dark-mode .panel-title a.accordion-toggle:before,
.dark-mode .panel-title a.accordion-toggle.collapsed:before {
    color: #fff;
}

.dark-mode .expanel {
    background-color: #2a2a4a !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.01) !important;
}

.dark-mode .expanel-default>.expanel-heading {
    background-color: #3b3b5a !important;
    border-color: #2a2a4a !important;
}

.dark-mode .expanel-heading {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .expanel-footer {
    background-color: #3b3b5a !important;
    border-top-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .popover {
    background-color: #2a2a4a;
    border-color: #dee3eb;
    filter: drop-shadow(0 1px 3px rgba(0, 0, 0, 0.1));
}

.dark-mode .bs-popover-top .popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=top] .popover-arrow::before {
    border-top-color: rgba(0, 0, 0, 0.25);
}

.dark-mode .bs-popover-top .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: #2a2a4a;
}

.dark-mode .bs-popover-end .popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=right] .popover-arrow::before {
    border-right-color: #dee3eb;
}

.dark-mode .bs-popover-end .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=right] .popover-arrow::after {
    border-right-color: #2a2a4a;
}

.dark-mode .bs-popover-bottom .popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::before {
    border-bottom-color: #dee3eb;
}

.dark-mode .bs-popover-bottom .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: #2a2a4a;
}

.dark-mode .bs-popover-bottom .popover-header::before,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    border-bottom-color: #2a2a4a;
}

.dark-mode .bs-popover-start .popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=left] .popover-arrow::before {
    border-left-color: #dee3eb;
}

.dark-mode .bs-popover-start .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=left] .popover-arrow::after {
    border-left-color: #2a2a4a;
}

.dark-mode .popover-header {
    color: inherit;
    background-color: #2a2a4a;
    border-bottom-color: #ebebeb;
}

.dark-mode .popover-body {
    color: #6e7687;
}

.dark-mode .progress {
    background-color: #1a1a3c;
}

.dark-mode .progress-bar {
    color: #fff;
}

.dark-mode .table {
    color: #dedefd;
}

.dark-mode .table thead th {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .text-wrap table thead th {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table tbody+tbody,
.dark-mode .text-wrap table tbody+tbody {
    border-top: 2px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .table .table,
.dark-mode .text-wrap table .table,
.dark-mode .table .text-wrap table {
    background-color: #1f1f3c;
}

.dark-mode .text-wrap .table table,
.dark-mode .text-wrap table table {
    background-color: #2a2a4a;
}

.dark-mode .table-bordered,
.dark-mode .text-wrap table,
.dark-mode .table-bordered th,
.dark-mode .text-wrap table th,
.dark-mode .table-bordered td,
.dark-mode .text-wrap table td {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.02);
}

.dark-mode .table-hover tbody tr:hover,
.dark-mode .table-hover tbody th {
    background-color: #1a1a3c;
    color: #dedefd;
}

.dark-mode .table-dark {
    background-color: #dedefd;
}

.dark-mode .table-dark>th,
.dark-mode .table-dark>td {
    background-color: #1a1a3c;
}

.dark-mode .table-hover .table-dark:hover {
    background-color: #b9bbbe;
}

.dark-mode .table-hover .table-dark:hover>td,
.dark-mode .table-hover .table-dark:hover>th {
    background-color: #b9bbbe;
}

.dark-mode .table> :not(:first-child) {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-active {
    background-color: rgba(0, 0, 0, 0.04);
}

.dark-mode .table-active>th,
.dark-mode .table-active>td {
    background-color: rgba(0, 0, 0, 0.04);
}

.dark-mode .table-hover .table-active:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.dark-mode .table-hover .table-active:hover>td,
.dark-mode .table-hover .table-active:hover>th {
    background-color: rgba(0, 0, 0, 0.04);
}

.dark-mode .table .thead-dark th,
.dark-mode .text-wrap table .thead-dark th {
    color: #dedefd;
    background-color: #dedefd;
    border-color: #32383e;
}

.dark-mode .table .thead-light th,
.dark-mode .text-wrap table .thead-light th {
    color: #495057;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-dark {
    color: #2a2a4a;
    background-color: #1a1a3c;
}

.dark-mode .table-dark th,
.dark-mode .table-dark td,
.dark-mode .table-dark thead th {
    border-bottom-color: rgba(255, 255, 255, 0.05) !important;
}

.dark-mode .table-dark.table-striped tbody tr:nth-of-type(odd) {
    background-color: #2a2a4a;
}

.dark-mode .table-dark.table-striped tbody tr:nth-of-type(even) {
    background-color: #272b2f;
}

.dark-mode .table-dark.table-hover tbody tr:hover {
    background-color: #dedefd;
}

.dark-mode .table-inbox {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-inbox tr {
    border-bottom-color: rgba(238, 238, 238, 0.7);
}

.dark-mode .table-inbox tr:last-child {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-inbox tr td .fa-star.inbox-started,
.dark-mode .table-inbox tr td .fa-star:hover {
    color: #f78a09;
}

.dark-mode .table-inbox tr.unread td {
    background: #2a2a4a;
}

.dark-mode .table th,
.dark-mode .text-wrap table th {
    color: #dedefd;
}

.dark-mode .table-vcenter td,
.dark-mode .table-vcenter th {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-secondary tbody+tbody,
.dark-mode .table-secondary td,
.dark-mode .table-secondary th,
.dark-mode .table-secondary thead th {
    border-color: rgba(255, 255, 255, 0.2);
}

.dark-mode .table-primary tbody+tbody,
.dark-mode .table-primary td,
.dark-mode .table-primary th,
.dark-mode .table-primary thead th {
    border-color: rgba(255, 255, 255, 0.2);
}

.dark-mode .table-striped tbody tr:nth-of-type(odd) {
    background: #1a1a3c;
    color: #dedefd;
}

.dark-mode .table-striped tbody tr:nth-of-type(even) {
    background-color: #2a2a4a;
}

.dark-mode .table-calendar-link {
    background: #f8f9fa;
    color: #495057;
}

.dark-mode .table-calendar-link:before {
    background: #467fcf;
}

.dark-mode .table-calendar-link:hover {
    color: #fff;
    background: #467fcf;
}

.dark-mode .table-calendar-link:hover:before {
    background: #2a2a4a;
}

.dark-mode .table-header:hover,
.dark-mode .table-header-asc,
.dark-mode .table-header-desc {
    color: #495057 !important;
}

.dark-mode .table-hover>tbody>tr:hover>* {
    color: #dedefd;
}

.dark-mode .table> :not(:last-child)> :last-child>* {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table tbody td {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .table-striped>tbody>tr:nth-of-type(odd)>* {
    color: #dedefd;
}

.dark-mode .tag {
    color: #dedefd;
    background-color: #1a1a3c;
}

.dark-mode a.tag:hover {
    background-color: rgba(110, 118, 135, 0.2);
    color: inherit;
}

.dark-mode .tag-addon {
    color: inherit;
}

.dark-mode a.tag-addon:hover {
    background: inherit;
    color: inherit;
}

.dark-mode .tag-blue {
    background-color: #467fcf !important;
    color: #fff;
}

.dark-mode .tag-indigo {
    background-color: #6574cd !important;
    color: #fff;
}

.dark-mode .tag-purple {
    background-color: #867efc !important;
    color: #fff;
}

.dark-mode .tag-pink {
    background-color: #ec82ef !important;
    color: #fff;
}

.dark-mode .tag-red {
    background-color: #c21a1a !important;
    color: #fff;
}

.dark-mode .tag-orange {
    background-color: #fc7303 !important;
    color: #fff;
}

.dark-mode .tag-yellow {
    background-color: #ecb403 !important;
    color: #fff;
}

.dark-mode .tag-green {
    background-color: #4ecc48 !important;
    color: #fff;
}

.dark-mode .tag-teal {
    background-color: #2bcbba !important;
    color: #fff;
}

.dark-mode .tag-cyan {
    background-color: #17a2b8 !important;
    color: #fff;
}

.dark-mode .tag-white {
    background-color: #2a2a4a;
    color: #fff;
}

.dark-mode .tag-gray {
    background-color: #868e96 !important;
    color: #fff;
}

.dark-mode .tag-gray-dark {
    background-color: #414160;
    color: #fff;
}

.dark-mode .tag-azure {
    background-color: #45aaf2 !important;
    color: #fff;
}

.dark-mode .tag-lime {
    background-color: #7bd235 !important;
    color: #fff;
}

.dark-mode .tag-primary {
    background-color: #467fcf;
    color: #fff;
    background-color: var(--primary-bg-color) !important;
    color: #fff;
}

.dark-mode .tag-secondary {
    background-color: #868e96;
    color: #fff;
}

.dark-mode .tag-success {
    background-color: #4ecc48 !important;
    color: #fff;
}

.dark-mode .tag-info {
    background-color: #45aaf2;
    color: #fff;
}

.dark-mode .tag-warning {
    background-color: #ecb403 !important;
    color: #fff;
}

.dark-mode .tag-danger {
    background-color: #c21a1a !important;
    color: #fff;
}

.dark-mode .tag-light {
    background-color: #f8f9fa;
    color: #fff;
}

.dark-mode .tag-dark {
    background-color: #25253e;
    color: #dedefd;
}

.dark-mode .tag-round::before {
    background-color: #2a2a4a;
}

.dark-mode .tag-outline-info {
    background-color: #c7e0fd;
    color: #1170e4;
    border-color: #1170e4;
}

.dark-mode .tag-outline-info::before {
    border-color: #1170e4;
}

.dark-mode .tag-outline {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tag-border {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
}

.dark-mode .thumbnail {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .bs-popover-top .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: #2a2a4a;
}

.dark-mode .bs-popover-end .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=right] .popover-arrow::after {
    border-right-color: #2a2a4a;
}

.dark-mode .bs-popover-bottom .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: #2a2a4a;
}

.dark-mode .bs-popover-start .popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=left] .popover-arrow::after {
    border-left-color: #2a2a4a;
}

.dark-mode .tooltip-static-demo {
    background-color: #2a2a4a;
}

.dark-mode .popover-static-demo {
    background-color: #1a1a3c;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tooltip-primary .tooltip-inner {
    background-color: var(--primary-bg-color) !important;
    color: #fff;
}

.dark-mode .tooltip-primary.bs-tooltip-top .tooltip-arrow::before,
.dark-mode .tooltip-primary.bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
    border-top-color: var(--primary-bg-color);
}

.dark-mode .tooltip-primary.bs-tooltip-bottom .tooltip-arrow::before,
.dark-mode .tooltip-primary.bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
    border-bottom-color: var(--primary-bg-color);
}

.dark-mode .tooltip-primary.bs-tooltip-start .tooltip-arrow::before,
.dark-mode .tooltip-primary.bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
    border-left-color: var(--primary-bg-color);
}

.dark-mode .tooltip-primary.bs-tooltip-end .tooltip-arrow::before,
.dark-mode .tooltip-primary.bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
    border-right-color: var(--primary-bg-color);
}

.dark-mode .popover {
    background-color: #2a2a4a;
    border: 0px solid #2a2a4a;
}

.dark-mode .bs-popover-top>.popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::before {
    border-top-color: #2a2a4a;
}

.dark-mode .bs-popover-top>.popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=top]>.popover-arrow::after {
    border-top-color: #2a2a4a;
}

.dark-mode .bs-popover-end>.popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::before {
    border-right-color: #2a2a4a;
}

.dark-mode .bs-popover-end>.popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=right]>.popover-arrow::after {
    border-right-color: #2a2a4a;
}

.dark-mode .bs-popover-bottom>.popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::before {
    border-bottom-color: #2a2a4a;
}

.dark-mode .bs-popover-bottom>.popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom]>.popover-arrow::after {
    border-bottom-color: #2a2a4a;
}

.dark-mode .bs-popover-bottom .popover-header::before,
.dark-mode .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .bs-popover-start>.popover-arrow::before,
.dark-mode .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::before {
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #2a2a4a;
}

.dark-mode .bs-popover-start>.popover-arrow::after,
.dark-mode .bs-popover-auto[data-popper-placement^=left]>.popover-arrow::after {
    border-width: 0.5rem 0 0.5rem 0.5rem;
    border-left-color: #2a2a4a;
}

.dark-mode .popover-header {
    color: #dedefd;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .popover-body {
    color: #aeaecf;
}

.dark-mode .popover-head-primary .popover-header {
    color: #fff;
    background-color: var(--primary-bg-color) !important;
}

.dark-mode .popover-head-primary.bs-popover-bottom .popover-arrow::after,
.dark-mode .popover-head-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: var(--primary-bg-color) !important;
}

.dark-mode .popover-head-secondary .popover-header {
    color: #fff;
    background-color: #05c3fb !important;
}

.dark-mode .popover-head-secondary.bs-popover-bottom .popover-arrow::after,
.dark-mode .popover-head-secondary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: #05c3fb !important;
}

.dark-mode .popover-head-primary .popover-body,
.dark-mode .popover-head-secondary .popover-body {
    border-color: rgba(20, 17, 45, 0.2);
}

.dark-mode .popover-primary {
    background-color: var(--primary-bg-color) !important;
}

.dark-mode .popover-primary .popover-header {
    background-color: var(--primary-bg-color) !important;
    border-bottom-width: 0 !important;
    color: #fff;
}

.dark-mode .popover-primary.bs-popover-top .popover-arrow::after,
.dark-mode .popover-primary.bs-popover-auto[data-popper-placement^=top] .popover-arrow::after {
    border-top-color: var(--primary-bg-color) !important;
}

.dark-mode .popover-primary.bs-popover-bottom .popover-arrow::after,
.dark-mode .popover-primary.bs-popover-auto[data-popper-placement^=bottom] .popover-arrow::after {
    border-bottom-color: var(--primary-bg-color) !important;
}

.dark-mode .popover-primary.bs-popover-start .popover-arrow::after,
.dark-mode .popover-primary.bs-popover-auto[data-popper-placement^=left] .popover-arrow::after {
    border-left-color: var(--primary-bg-color) !important;
}

.dark-mode .popover-primary.bs-popover-end .popover-arrow::after,
.dark-mode .popover-primary.bs-popover-auto[data-popper-placement^=right] .popover-arrow::after {
    border-right-color: var(--primary-bg-color) !important;
}

.dark-mode .custom-control-input:checked~.custom-control-label::before {
    color: #fff;
}

.dark-mode .custom-control-input:active~.custom-control-label::before {
    color: #fff;
    background-color: rgba(218, 201, 232, 0.5);
}

.dark-mode .custom-control-input:disabled~.custom-control-label {
    color: #868e96;
}

.dark-mode .custom-control-input:disabled~.custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-checkbox .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(212, 182, 228, 0.5);
}

.dark-mode .form-select {
    color: #9595b5;
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .form-select:focus {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: none;
}

.dark-mode .form-select:focus::-ms-value {
    color: #9595b5;
    background-color: #2a2a4a;
}

.dark-mode .form-select:disabled {
    color: #9fa7af;
    background-color: #2a2a4a;
}

.dark-mode .form-file-label {
    color: #9595b5;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .form-file-label::after {
    color: #fff;
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .form-range::-webkit-slider-thumb {
    background: #2a2a4a;
    box-shadow: none;
}

.dark-mode .form-range::-webkit-slider-thumb:focus {
    box-shadow: 0 0 0 1px #2a2a4a, 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.dark-mode .form-range::-webkit-slider-thumb:active {
    background-color: #d4e1f4;
}

.dark-mode .form-range::-webkit-slider-runnable-track {
    background-color: rgba(255, 255, 255, 0.1);
    background: #467fcf;
}

.dark-mode .form-range::-moz-range-thumb {
    background: #2a2a4a;
}

.dark-mode .form-range::-moz-range-thumb:focus {
    box-shadow: 0 0 0 1px #2a2a4a, 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.dark-mode .form-range::-moz-range-thumb:active {
    background-color: #d4e1f4;
}

.dark-mode .form-range::-moz-range-track {
    background-color: rgba(255, 255, 255, 0.1);
    background: rgba(0, 50, 126, 0.12);
}

.dark-mode .form-range::-ms-thumb {
    background: #2a2a4a;
    border-color: rgba(0, 30, 75, 0.12);
}

.dark-mode .form-range::-ms-thumb:focus {
    box-shadow: 0 0 0 1px #2a2a4a, 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.dark-mode .form-range::-ms-thumb:active {
    background-color: #d4e1f4;
}

.dark-mode .form-range::-ms-fill-lower {
    background-color: rgba(255, 255, 255, 0.1);
    background: #467fcf;
}

.dark-mode .custom-control-label:before {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.02);
}

.dark-mode .form-range:focus::-webkit-slider-thumb,
.dark-mode .form-range:focus::-moz-range-thumb,
.dark-mode .form-range:focus::-ms-thumb {
    border-color: #467fcf;
    background-color: #467fcf;
}

.dark-mode .form-range::-moz-range-progress {
    background: #467fcf;
}

.dark-mode .form-range::-ms-fill-upper {
    background: rgba(0, 50, 126, 0.12);
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-switch-description {
    color: #9595b5;
}

.dark-mode .custom-switch-input:checked~.custom-switch-description {
    color: #9595b5;
}

.dark-mode .custom-switch-indicator {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-switch-indicator:before {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.dark-mode .custom-switch-input:focus~.custom-switch-indicator {
    border-color: #60529f;
}

.dark-mode .custom-radio-md .custom-control-label::before,
.dark-mode .custom-radio-lg .custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-checkbox-md .custom-control-label::before,
.dark-mode .custom-checkbox-lg .custom-control-label::before {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-checkbox-md .custom-control-input:disabled:indeterminate~.custom-control-label::before,
.dark-mode .custom-checkbox-lg .custom-control-input:disabled:indeterminate~.custom-control-label::before {
    background-color: rgba(212, 182, 228, 0.5);
}

.dark-mode .custom-switch-input:disabled~.custom-switch-indicator {
    background: #e9ecef;
}

.dark-mode .custom-switch-input:checked~.custom-switch-indicator {
    background: var(--primary-bg-color);
}

.dark-mode .custom-switch-indicator-md,
.dark-mode .custom-switch-indicator-lg {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .custom-switch-indicator-md::before,
.dark-mode .custom-switch-indicator-lg::before {
    background: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

.dark-mode .collapse:not(.show) {
    background: #2a2a4a;
}

.dark-mode .notifyimg {
    color: #fff;
}

.dark-mode .aside {
    background: #2a2a4a;
    border-left-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.05);
}

.dark-mode a.icon:hover {
    color: #dcdfed !important;
}

@media (max-width: 992px) {
    .dark-mode .about-con {
        border-bottom-color: rgba(255, 255, 255, 0.1);
    }
}

@media (max-width: 480px) {

    .dark-mode .tabs-menu ul li a,
    .dark-mode .tabs-menu1 ul li {
        border-color: rgba(255, 255, 255, 0.1);
    }
}

@media (max-width: 320px) {
    .dark-mode .construction .btn.btn-icon {
        color: #fff;
    }
}

@media (max-width: 360px) {
    .dark-mode .breadcrumb {
        color: #fff;
    }
}

@media (max-width: 768px) {
    .dark-mode .richText .richText-toolbar ul li a {
        border: rgba(0, 40, 100, 0.12) solid 1px;
    }

    .dark-mode .richText .richText-toolbar ul li {
        border-bottom: rgba(255, 255, 255, 0.1) solid 1px;
    }

    .dark-mode .richText .richText-toolbar {
        border-bottom: 0 !important;
    }
}

.dark-mode .stamp {
    color: #fff;
    background: #868e96;
}

.dark-mode .example {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .example-bg {
    background: #2a2a4a;
}

.dark-mode .colorinput-color {
    border: 3px solid rgba(255, 255, 255, 0.1);
    color: #fff;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.dark-mode .colorinput-input:focus~.colorinput-color {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.dark-mode #back-to-top {
    color: #fff;
}

.dark-mode #back-to-top:hover {
    background: #fff !important;
}

.dark-mode .features span {
    color: #43414e;
}

.dark-mode .feature .border {
    color: #fff;
}

.dark-mode .actions:not(.a-alt)>li>a>i {
    color: #939393;
}

.dark-mode #sidebar li a.active {
    background: #2a2a4a;
}

.dark-mode #sidebar li a.active a[data-toggle=collapse] {
    background: #2a2a4a;
}

.dark-mode .line-divide {
    border-color: rgba(218, 216, 219, 0.2);
}

.dark-mode .activity {
    border-left-color: rgba(0, 0, 0, 0.125);
}

.dark-mode .username {
    color: #fff;
}

.dark-mode .username+p {
    color: #f2f2f2;
}

.dark-mode #user-profile .profile-details ul>li>span {
    color: #643ba2;
}

@media (max-width: 1024px) {

    .dark-mode body.search-show:before,
    .dark-mode body.sidebar-show:before {
        background-color: rgba(0, 0, 0, 0.4);
    }
}

@media (max-width: 575.98px) {
    .dark-mode .header .form-inline .search-element .form-control {
        background: #2a2a4a;
    }

    .dark-mode .form-control.header-search {
        color: #000;
    }

    .dark-mode .header .form-inline .btn {
        color: #46494a !important;
    }

    .dark-mode .header .form-inline .form-control::-webkit-input-placeholder {
        color: #fff !important;
    }

    .dark-mode .header .navsearch i {
        color: #fff;
    }
}

.dark-mode .settings {
    color: #fff;
}

.dark-mode .member {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 998px) and (min-width: 768px) {

    .dark-mode .note-popover .popover-content,
    .dark-mode .card-header.note-toolbar {
        background: #2a2a4a;
    }
}

.dark-mode .material-switch>label::before {
    background: #9595b5;
    box-shadow: inset 0px 0px 10px rgba(42, 38, 53, 0.5);
}

.dark-mode .material-switch>label::after {
    background: #3d3d5a;
    box-shadow: 0px 0px 5px rgba(42, 38, 53, 0.9);
}

.dark-mode .material-switch>input[type=checkbox]:checked+label::before,
.dark-mode .material-switch>input[type=checkbox]:checked+label::after {
    background: inherit;
}

.dark-mode .sw-theme-default>ul.step-anchor>li>a::after {
    background: none !important;
}

.dark-mode .border-transparet {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .breadcrumb .breadcrumb-item.active,
.dark-mode .breadcrumb-item1 a {
    color: var(--primary-bg-color);
}

.dark-mode .input-group.input-indec .form-control {
    border-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 992px) {
    .dark-mode .main-header-center .form-control {
        background: #2a2a4a;
        border-color: rgba(255, 255, 255, 0.1);
    }

    .dark-mode.header-light .main-header-center .form-control {
        background: #fff;
        border: 1px solid #e9edf4;
    }
}

@media (min-width: 992px) {
    .dark-mode .main-header-center .btn {
        background-color: transparent;
        color: #b4bdce;
    }
}

.dark-mode .pulse-danger {
    background: #ee335e;
}

.dark-mode .pulse-danger:before {
    background: rgba(238, 51, 94, 0.8);
    box-shadow: 0 0 0 rgba(238, 51, 94, 0.9);
}

.dark-mode .pulse {
    background: #22c03c;
}

.dark-mode .pulse:before {
    background: rgba(34, 192, 60, 0.6);
    box-shadow: 0 0 0 rgba(34, 192, 60, 0.9);
}

.dark-mode .progress.info1 {
    background: #fed5db;
}

.dark-mode .progress.secondary {
    background: #f9d1bd;
}

.dark-mode .avatar {
    color: #fff;
}

.dark-mode .badge-success-light {
    background-color: rgba(19, 191, 27, 0.15) !important;
    color: #13bf1b;
}

.dark-mode .badge-orange-light {
    color: #e17626;
    background-color: rgba(225, 118, 38, 0.15);
}

.dark-mode .badge-danger-light {
    color: #f33819;
    background-color: rgba(243, 56, 25, 0.15);
}

.dark-mode .badge.bg-white {
    background-color: #fff !important;
}

.dark-mode .browser-stats img {
    background: #1a1a3c;
}

.dark-mode .box-shadow-primary {
    box-shadow: 0 5px 10px #42444a;
}

.dark-mode .box-shadow-secondary {
    box-shadow: 0 5px 10px #4e4642;
}

.dark-mode .box-shadow-success {
    box-shadow: 0 5px 10px #45504e;
}

.dark-mode .box-shadow-danger {
    box-shadow: 0 5px 10px #524a4b;
}

.dark-mode .box-shadow-pink {
    box-shadow: 0 5px 10px #544c4f;
}

.dark-mode .sidebar .tab-menu-heading {
    background: var(--primary-bg-color);
    color: #fff;
}

.dark-mode .sidebar-right .dropdown-item h6 {
    color: #282f53;
}

.dark-mode .nav.panel-tabs a.active {
    color: var(--primary-bg-color);
}

.dark-mode .nav.panel-tabs.panel-secondary a {
    color: #05c3fb;
}

.dark-mode .nav.panel-tabs.panel-secondary a.active {
    background-color: #05c3fb !important;
    color: #fff;
}

.dark-mode .nav.panel-tabs.panel-danger a {
    color: #e82646;
}

.dark-mode .nav.panel-tabs.panel-danger a.active {
    background-color: #e82646 !important;
    color: #fff;
}

.dark-mode .nav.panel-tabs.panel-success a {
    color: #09ad95;
}

.dark-mode .nav.panel-tabs.panel-success a.active {
    background-color: #09ad95 !important;
    color: #fff;
}

.dark-mode .nav.panel-tabs.panel-info a {
    color: #1170e4;
}

.dark-mode .nav.panel-tabs.panel-info a.active {
    background-color: #1170e4 !important;
    color: #fff;
}

.dark-mode .task-list {
    color: #dedefd;
}

.dark-mode .task-list:before {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .mail-inbox .icons {
    color: #dedefd !important;
}

.dark-mode .table-inbox tr td i {
    color: #4e4c6a;
}

.dark-mode .table-inbox tr td i:hover {
    color: #f7284a;
}

.dark-mode .table-inbox tr td .fa-star:hover {
    color: #fbc518;
}

.dark-mode .mail-option .btn-group a.btn,
.dark-mode .mail-option .chk-all {
    border-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .mail-option .btn-group a.all {
    box-shadow: none;
}

.dark-mode .inbox-pagination a.np-btn {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .acc-header a {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .acc-header a.collapsed {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .acc-body {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .card-pay .tabs-menu li a {
    border-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .card-pay .tabs-menu li a.active {
    background: var(--primary-bg-color);
    color: #fff;
}

.dark-mode .main-content-label,
.dark-mode .card-table-two .card-title,
.dark-mode .card-dashboard-eight .card-title {
    color: #dedefd;
}

.dark-mode .social-login {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .user-social-detail .social-profile {
    background: #383862;
}

.dark-mode .sticky.sticky-pin .horizontal-main.hor-menu {
    box-shadow: 5px 7px 26px -5px rgba(104, 113, 123, 0.1);
}

.dark-mode .mini-stat-icon {
    color: #fff;
}

.dark-mode .product-grid6 {
    overflow: hidden;
}

.dark-mode .product-grid6 .price span {
    color: #9595b5;
}

.dark-mode .product-grid6 .icons-wishlist li a i {
    color: inherit;
}

.dark-mode .product-grid6 .icons-wishlist li a:hover,
.dark-mode .product-grid6 .icons-wishlist li a:after,
.dark-mode .product-grid6 .icons-wishlist li a:before {
    color: #fff;
}

.dark-mode .product-grid6 .icons li a i {
    color: inherit;
}

.dark-mode .product-grid6 .icons li a:hover,
.dark-mode .product-grid6 .icons li a:after,
.dark-mode .product-grid6 .icons li a:before {
    color: #fff;
}

.dark-mode .apexcharts-radialbar-track.apexcharts-track path {
    stroke: #2a2a4a;
}

.dark-mode .apex-charts text {
    fill: #000200;
}

.dark-mode .offcanvas {
    background: #2a2a4a;
}

.dark-mode .offcanvas-start {
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .offcanvas-end {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .offcanvas-bottom {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .offcanvas-top {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .toast {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.dark-mode .toast .toast-header {
    background: #2a2a4a;
    color: #dedefd;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .toast.show .btn-close {
    color: #d5d5d5;
}

.dark-mode .task-icon1:first-child {
    border: 2px solid var(--primary-bg-color);
}

.dark-mode .nav.product-sale a.active {
    background-color: #2a2a4a;
    border-bottom: none;
}

.dark-mode .profile-cover__img {
    color: #fff;
}

.dark-mode .profile-cover__img .profile-img-1>img {
    border: 5px solid #ffffff;
}

.dark-mode .profile-cover__img>.h3 {
    color: #393939;
}

.dark-mode .profile-cover__info .nav li {
    color: #464461;
}

.dark-mode .social.social-profile-buttons .social-icon {
    background: #3b366c;
    border-color: rgba(255, 255, 255, 0.1);
    color: #abb4c7;
}

.dark-mode .profile-share {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .option-dots {
    color: #5c678f;
}

.dark-mode .option-dots:focus,
.dark-mode .option-dots:hover,
.dark-mode .option-dots[aria-expanded=true] {
    background: #3b3b64;
}

@media (min-width: 601px) {
    .dark-mode .social-profile-buttons .nav {
        color: #999;
    }
}

.dark-mode .social-profile-buttons .nav li {
    color: #464461;
}

.dark-mode .item2-gl-menu {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .item2-gl-menu li a {
    color: #b0b0c5;
}

.dark-mode .item2-gl-menu li .active {
    color: var(--primary-bg-color);
}

.dark-mode .product-label {
    background: rgba(178, 177, 183, 0.1);
}

.dark-mode .ui-state-hover,
.dark-mode .ui-widget-content .ui-state-hover,
.dark-mode .ui-widget-header .ui-state-hover,
.dark-mode .ui-state-focus,
.dark-mode .ui-widget-content .ui-state-focus,
.dark-mode .ui-widget-header .ui-state-focus {
    border-color: var(--primary-bg-color) !important;
    background: var(--primary-bg-color) !important;
}

.dark-mode .ui-widget-content {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .product-list .icons li a:after,
.dark-mode .product-list .icons li a:before {
    color: #fff;
}

.dark-mode .product-grid6 .card-footer .btn-outline-primary:hover {
    color: #fff;
}

.dark-mode .carousel-inner .carousel-item .thumb {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .carousel-inner .carousel-item .thumb.active {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .customer-services {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .customer-services span {
    background-color: #2a2a4a;
    color: var(--primary-bg-color);
}

.dark-mode .login-social-icon::before,
.dark-mode .login-social-icon::after {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .login-social-icon span {
    background: #2a2a4a;
}

.dark-mode .custom-layout {
    color: #fff;
}

.dark-mode .custom-layout .nav-link.icon i {
    color: #fff !important;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
    background: rgba(255, 255, 255, 0.08);
}

.dark-mode .custom-layout .nav-link.icon i::after {
    background-color: transparent;
}

.dark-mode .dark-mode .custom-layout .nav-link.icon i {
    color: #fff !important;
}

.dark-mode .country-selector .nav-link {
    color: #495046;
}

.dark-mode .country {
    color: #f7f7f7;
}

.dark-mode .country span:hover {
    color: #f7f7f7;
}

.dark-mode .theme-container .active {
    border-color: var(--primary-bg-color);
    background: #2a2a4a;
    color: var(--primary-bg-color);
}

.dark-mode .theme-container1 .active {
    border-color: #ec82ef;
    background: #2a2a4a;
    color: #ec82ef;
}

.dark-mode .theme-container2 .active {
    border-color: #05c3fb;
    background: #2a2a4a;
    color: #05c3fb;
}

.dark-mode .settings-icon {
    border-color: var(--primary-bg-color);
}

.dark-mode .input-group-text.input-text-color {
    background-color: #2a2a4a;
}

.dark-mode .payment-icon.active svg {
    fill: #fff;
}

.dark-mode .payment-icon svg {
    fill: #dedefd;
}

.dark-mode .notification:before {
    background: var(--primary02);
}

.dark-mode .notification .notification-time .date,
.dark-mode .notification .notification-time .time {
    color: #8f8fb1;
}

.dark-mode .notification .notification-icon a {
    background: #2a2a4a;
    color: #fff;
    border: 3px solid var(--primary-bg-color);
}

.dark-mode .notification .notification-body {
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.03);
    background: #2a2a4a;
}

.dark-mode .notification .notification-body:before {
    border: 10px solid transparent;
    border-right-color: #2a2a4a;
}

.dark-mode .notification-time-date {
    color: #8f8fb1;
}

.dark-mode .btn-country {
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: none !important;
    color: #dedefd;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
}

.dark-mode .btn-country:hover {
    border-color: var(--primary-bg-color) !important;
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.1) !important;
}

.dark-mode .btn-check:checked+.btn-country {
    border-color: var(--primary-bg-color) !important;
}

.dark-mode .btn-country.active,
.dark-mode .btn-country:active {
    border-color: var(--primary-bg-color) !important;
}

.dark-mode .email-icon {
    border-color: rgba(255, 255, 255, 0.1);
    color: #5c678f;
}

.dark-mode .product-grid6 .card-footer .btn-outline-primary:hover .wishlist-icon {
    color: #fff;
}

.dark-mode .btn-outline-primary .wishlist-icon {
    color: var(--primary-bg-color) !important;
}

.dark-mode .btn-outline-primary:hover .wishlist-icon {
    color: #fff !important;
}

.dark-mode #chartZoom .btn-outline-primary:hover {
    color: #fff;
}

.dark-mode .file-image .icons li a {
    color: #fff;
}

.dark-mode .file-image .icons li a:after,
.dark-mode .file-image .icons li a:before,
.dark-mode .file-image .icons li a:hover {
    color: #fff;
}

.dark-mode .file-name {
    color: #fff;
}

.dark-mode .img-1 img {
    border: 6px solid rgba(225, 225, 225, 0.5);
}

.dark-mode .profile-img {
    border-color: rgba(167, 180, 201, 0.2);
    background: rgba(225, 225, 225, 0.2);
}

.dark-mode .demo-gallery>ul>li a {
    border: 3px solid #fff;
}

.dark-mode .demo-gallery>ul>li a .demo-gallery-poster {
    background-color: rgba(0, 0, 0, 0.1);
}

.dark-mode .demo-gallery.dark>ul>li a {
    border: 3px solid #04070a;
}

.dark-mode .gallery a img {
    border-color: rgba(0, 0, 0, 0.2);
}

.dark-mode .example+.highlight {
    border-top: none;
}

.dark-mode .highlight {
    border-color: rgba(255, 255, 255, 0.1);
    border-top: none;
    background: #2a2a4a;
}

.dark-mode .highlight .hll {
    background-color: #ffc;
}

.dark-mode .highlight .c {
    color: #999;
}

.dark-mode .highlight .k {
    color: #069;
}

.dark-mode .highlight .o {
    color: #555;
}

.dark-mode .highlight .cm {
    color: #999;
}

.dark-mode .highlight .cp {
    color: #099;
}

.dark-mode .highlight .c1,
.dark-mode .highlight .cs {
    color: #999;
}

.dark-mode .highlight .gd {
    background-color: #fcc;
    border-color: #c00;
}

.dark-mode .highlight .gr {
    color: #f00;
}

.dark-mode .highlight .gh {
    color: #030;
}

.dark-mode .highlight .gi {
    background-color: #cfc;
    border-color: #0c0;
}

.dark-mode .highlight .go {
    color: #aaa;
}

.dark-mode .highlight .gp {
    color: #009;
}

.dark-mode .highlight .gu {
    color: #030;
}

.dark-mode .highlight .gt {
    color: #9c6;
}

.dark-mode .highlight .kc,
.dark-mode .highlight .kd,
.dark-mode .highlight .kn,
.dark-mode .highlight .kp,
.dark-mode .highlight .kr {
    color: #069;
}

.dark-mode .highlight .kt {
    color: #078;
}

.dark-mode .highlight .m {
    color: #f60;
}

.dark-mode .highlight .s {
    color: #cc0099;
}

.dark-mode .highlight .na {
    color: #00cc7a;
}

.dark-mode .highlight .nb {
    color: #366;
}

.dark-mode .highlight .nc {
    color: #0a8;
}

.dark-mode .highlight .no {
    color: #360;
}

.dark-mode .highlight .nd {
    color: #99f;
}

.dark-mode .highlight .ni {
    color: #999;
}

.dark-mode .highlight .ne {
    color: #c00;
}

.dark-mode .highlight .nf {
    color: #c0f;
}

.dark-mode .highlight .nl {
    color: #99f;
}

.dark-mode .highlight .nn {
    color: #0cf;
}

.dark-mode .highlight .nt {
    color: #e12020;
}

.dark-mode .highlight .nv {
    color: #033;
}

.dark-mode .highlight .ow {
    color: #000;
}

.dark-mode .highlight .w {
    color: #bbb;
}

.dark-mode .highlight .mf,
.dark-mode .highlight .mh,
.dark-mode .highlight .mi,
.dark-mode .highlight .mo {
    color: #f60;
}

.dark-mode .highlight .sb,
.dark-mode .highlight .sc,
.dark-mode .highlight .sd,
.dark-mode .highlight .s2,
.dark-mode .highlight .se,
.dark-mode .highlight .sh {
    color: #c30;
}

.dark-mode .highlight .si {
    color: #a00;
}

.dark-mode .highlight .sx {
    color: #c30;
}

.dark-mode .highlight .sr {
    color: #3aa;
}

.dark-mode .highlight .s1 {
    color: #c30;
}

.dark-mode .highlight .ss {
    color: #fc3;
}

.dark-mode .highlight .bp {
    color: #366;
}

.dark-mode .highlight .vc,
.dark-mode .highlight .vg,
.dark-mode .highlight .vi {
    color: #033;
}

.dark-mode .highlight .il {
    color: #f60;
}

.dark-mode .highlight .css .o {
    color: #999;
}

.dark-mode .highlight .css .o+.nt {
    color: #999;
}

.dark-mode .highlight .css .nt+.nt {
    color: #999;
}

.dark-mode .highlight .language-bash::before,
.dark-mode .highlight .language-sh::before,
.dark-mode .highlight .language-powershell::before {
    color: #009;
}

.dark-mode .label-default {
    background: #d5e0ec;
    color: #fff;
}

.dark-mode .label-success {
    background: #09ad95;
    color: #fff;
}

.dark-mode .label-danger {
    background: #f5334f;
    color: #fff;
}

.dark-mode .label-warning {
    background: #f7b731;
    color: #fff;
}

.dark-mode .label-info {
    background: #1170e4;
    color: #fff;
}

.dark-mode .list-group-item.active {
    background-color: #292948;
    color: #dedefd;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .list-group-item-action {
    color: #9595b5;
}

.dark-mode .list-group-item-action:hover,
.dark-mode .list-group-item-action:focus,
.dark-mode .list-group-item-action:active {
    color: #9595b5;
    background-color: #2a2a4a;
}

.dark-mode .list-group-item,
.dark-mode .listorder,
.dark-mode .listorder1,
.dark-mode .listunorder,
.dark-mode .listunorder1 {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    color: #9595b5;
}

.dark-mode .list-group-item.disabled,
.dark-mode .list-group-item:disabled {
    color: #545c74;
    background-color: #252542;
}

.dark-mode .list-group-item-primary {
    color: #24426c !important;
    background-color: #cbdbf2;
}

.dark-mode .list-group-item-primary.list-group-item-action:hover,
.dark-mode .list-group-item-primary.list-group-item-action:focus {
    color: #24426c;
    background-color: #b7cded;
}

.dark-mode .list-group-item-primary.list-group-item-action.active {
    color: #fff;
    background-color: #24426c;
    border-color: #24426c;
}

.dark-mode .list-group-item-secondary {
    color: #464a4e !important;
    background-color: #dddfe2;
}

.dark-mode .list-group-item-secondary.list-group-item-action:hover,
.dark-mode .list-group-item-secondary.list-group-item-action:focus {
    color: #464a4e;
    background-color: #cfd2d6;
}

.dark-mode .list-group-item-secondary.list-group-item-action.active {
    color: #fff;
    background-color: #464a4e;
    border-color: #464a4e;
}

.dark-mode .list-group-item-success {
    color: #09ad95 !important;
    background-color: rgba(9, 173, 149, 0.4);
}

.dark-mode .list-group-item-success.list-group-item-action:hover,
.dark-mode .list-group-item-success.list-group-item-action:focus {
    color: #09ad95;
    background-color: rgba(9, 173, 149, 0.2);
}

.dark-mode .list-group-item-success.list-group-item-action.active {
    color: #fff;
    background-color: #09ad95;
    border-color: #09ad95;
}

.dark-mode .list-group-item-info {
    color: #1170e4 !important;
    background-color: rgba(17, 112, 228, 0.4);
}

.dark-mode .list-group-item-info.list-group-item-action:hover,
.dark-mode .list-group-item-info.list-group-item-action:focus {
    color: #1170e4;
    background-color: rgba(17, 112, 228, 0.2);
}

.dark-mode .list-group-item-info.list-group-item-action.active {
    color: #fff;
    background-color: rgba(17, 112, 228, 0.2);
    border-color: rgba(17, 112, 228, 0.2);
}

.dark-mode .list-group-item-warning {
    color: #f7b731 !important;
    background-color: rgba(247, 183, 49, 0.4);
}

.dark-mode .list-group-item-warning.list-group-item-action:hover,
.dark-mode .list-group-item-warning.list-group-item-action:focus {
    color: #f7b731;
    background-color: rgba(247, 183, 49, 0.2);
}

.dark-mode .list-group-item-warning.list-group-item-action.active {
    color: #fff;
    background-color: #f7b731;
    border-color: #f7b731;
}

.dark-mode .list-group-item-danger {
    color: #e82646 !important;
    background-color: rgba(232, 38, 70, 0.4);
}

.dark-mode .list-group-item-danger.list-group-item-action:hover,
.dark-mode .list-group-item-danger.list-group-item-action:focus {
    color: #e82646;
    background-color: rgba(232, 38, 70, 0.2);
}

.dark-mode .list-group-item-danger.list-group-item-action.active {
    color: #fff;
    background-color: #e82646;
    border-color: #e82646;
}

.dark-mode .list-group-item-light {
    color: #818182;
    background-color: #fdfdfe;
}

.dark-mode .list-group-item-light.list-group-item-action:hover,
.dark-mode .list-group-item-light.list-group-item-action:focus {
    color: #818182;
    background-color: #ececf6;
}

.dark-mode .list-group-item-light.list-group-item-action.active {
    color: #fff;
    background-color: #818182;
    border-color: #818182;
}

.dark-mode .list-group-item-dark {
    color: #1b1e21;
    background-color: #c6c8ca;
}

.dark-mode .list-group-item-dark.list-group-item-action:hover,
.dark-mode .list-group-item-dark.list-group-item-action:focus {
    color: #1b1e21;
    background-color: #b9bbbe;
}

.dark-mode .list-group-item-dark.list-group-item-action.active {
    color: #fff;
    background-color: #1b1e21;
    border-color: #1b1e21;
}

.dark-mode .list-group-item {
    border-color: rgba(255, 255, 255, 0.1);
    color: #9595b5;
}

.dark-mode .list-group-item.active .icon {
    color: inherit !important;
}

.dark-mode .list-group-item .icon {
    color: #dedefd !important;
}

.dark-mode .list-group-transparent .list-group-item.active {
    background: #3b3b60;
    color: #dedefd;
}

.dark-mode .list-group-transparent.file-manager .list-group-item {
    color: #dedefd;
}

.dark-mode .list-group-transparent.file-manager.file-manager-border .list-group-item {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .file-image-1 {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .file-image-1 .file-name-1 {
    color: #dedefd;
}

.dark-mode .file-radius-attachments i {
    color: #dedefd;
}

.dark-mode .file-square-attachments a {
    color: #dedefd;
}

.dark-mode .btn-close {
    color: #9595b5;
}

.dark-mode .btn-close:hover,
.dark-mode .btn-close:focus {
    color: inherit;
}

.dark-mode .navbar-toggler {
    background-color: transparent;
    border-color: transparent;
}

@media (min-width: 992px) {
    .dark-mode .responsive-navbar .navbar-collapse {
        background: transparent;
    }
}

.dark-mode .navbar-light .navbar-brand {
    color: #dedefd;
}

.dark-mode .navbar-light .navbar-brand:hover,
.dark-mode .navbar-light .navbar-brand:focus {
    color: #dedefd;
}

.dark-mode .navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 0.5);
}

.dark-mode .navbar-light .navbar-nav .nav-link:hover,
.dark-mode .navbar-light .navbar-nav .nav-link:focus {
    color: rgba(0, 0, 0, 0.7);
}

.dark-mode .navbar-light .navbar-nav .nav-link.disabled {
    color: rgba(0, 0, 0, 0.3);
}

.dark-mode .navbar-light .navbar-nav .show>.nav-link,
.dark-mode .navbar-light .navbar-nav .active>.nav-link {
    color: rgba(0, 0, 0, 0.9);
}

.dark-mode .navbar-light .navbar-nav .nav-link.show,
.dark-mode .navbar-light .navbar-nav .nav-link.active {
    color: rgba(0, 0, 0, 0.9);
}

.dark-mode .navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.5);
    border-color: rgba(0, 0, 0, 0.1);
}

.dark-mode .navbar-light .navbar-text {
    color: rgba(0, 0, 0, 0.5);
}

.dark-mode .navbar-light .navbar-text a {
    color: rgba(0, 0, 0, 0.9);
}

.dark-mode .navbar-light .navbar-text a:hover,
.dark-mode .navbar-light .navbar-text a:focus {
    color: rgba(0, 0, 0, 0.9);
}

.dark-mode .navbar-dark .navbar-brand {
    color: #fff;
}

.dark-mode .navbar-dark .navbar-brand:hover,
.dark-mode .navbar-dark .navbar-brand:focus {
    color: #fff;
}

.dark-mode .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.5);
}

.dark-mode .navbar-dark .navbar-nav .nav-link:hover,
.dark-mode .navbar-dark .navbar-nav .nav-link:focus {
    color: rgba(255, 255, 255, 0.75);
}

.dark-mode .navbar-dark .navbar-nav .nav-link.disabled {
    color: rgba(255, 255, 255, 0.25);
}

.dark-mode .navbar-dark .navbar-nav .show>.nav-link,
.dark-mode .navbar-dark .navbar-nav .active>.nav-link {
    color: #fff;
}

.dark-mode .navbar-dark .navbar-nav .nav-link.show,
.dark-mode .navbar-dark .navbar-nav .nav-link.active {
    color: #fff;
}

.dark-mode .navbar-dark .navbar-toggler {
    color: rgba(255, 255, 255, 0.5);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .navbar-dark .navbar-text {
    color: rgba(255, 255, 255, 0.5);
}

.dark-mode .navbar-dark .navbar-text a {
    color: #fff;
}

.dark-mode .navbar-dark .navbar-text a:hover,
.dark-mode .navbar-dark .navbar-text a:focus {
    color: #fff;
}

@media (max-width: 992px) {
    .dark-mode .navresponsive-toggler span {
        color: #dcdfed;
    }

    .dark-mode .navresponsive-toggler span:after {
        background: rgba(var(--primary-bg-color), 0);
    }

    .dark-mode .responsive-navbar .navbar-collapse .icon.navsearch {
        border-color: #e4e6f9;
    }
}

@media (max-width: 991px) {
    .dark-mode .responsive-navbar .navbar-collapse {
        background: #2a2a4a;
        box-shadow: 0 12px 11px -3px rgba(104, 113, 123, 0.1);
        border-top-color: rgba(255, 255, 255, 0.1);
    }
}

.dark-mode .scrollspy-example {
    border-right-color: rgba(255, 255, 255, 0.1);
    border-left-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .scrollspy-example-2 {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode #navbar-example3 .nav-link {
    color: #dedefd;
}

.dark-mode #navbar-example3 .nav-link.active {
    color: #fff;
}

.dark-mode .nav-link:focus,
.dark-mode .nav-link:hover {
    color: var(--primary-bg-color);
}

.dark-mode .pricing {
    color: #fff;
}

.dark-mode .pricing1 {
    color: #707070;
}

.dark-mode .pricing .list-unstyled li {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .pricing1 .list-unstyled li {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .panel-heading {
    border-bottom-color: rgba(255, 255, 255, 0.2);
    background: #2a2a4a;
}

.dark-mode .panel.price {
    box-shadow: 0 0.15rem 1.75rem 0 #0e0f2e;
    background: #2a2a4a;
}

.dark-mode .panel.price>.panel-heading {
    color: #fff;
}

.dark-mode .price .panel-footer {
    background-color: #2a2a4a;
}

.dark-mode .price.panel-color>.panel-body {
    background-color: #2a2a4a;
}

.dark-mode .ribbone1-price .ribbon span {
    color: #fff;
    background: #79a70a;
    background: var(--primary-bg-color);
    box-shadow: 0 3px 10px -5px black;
}

.dark-mode .ribbone1-price .ribbon span::before {
    border-left: 3px solid var(--primary-bg-color);
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid var(--primary-bg-color);
}

.dark-mode .ribbone1-price .ribbon span::after {
    border-left: 3px solid transparent;
    border-right: 3px solid var(--primary-bg-color);
    border-bottom: 3px solid transparent;
    border-top: 3px solid var(--primary-bg-color);
}

.dark-mode .secondary .pricing-divider {
    background: #2a2a4a;
}

.dark-mode .danger .pricing-divider {
    background: #2a2a4a !important;
}

.dark-mode .primary .pricing-divider {
    background: #2a2a4a !important;
}

.dark-mode .success .pricing-divider {
    background: #2a2a4a;
}

.dark-mode .rating-stars input {
    color: #495057;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tabs-menu ul li a {
    color: #dedefd;
}

.dark-mode .tabs-menu ul li .active {
    color: var(--primary-bg-color);
}

.dark-mode .tabs-menu1 ul li a {
    color: #dedefd;
}

.dark-mode .tab-menu-heading {
    border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .tabs-menu2 ul li a {
    color: #636262;
}

.dark-mode .tabs-menu2 ul li .fade {
    color: #eeee;
}

.dark-mode .search-tabs ul li a.active {
    border-bottom: 3px solid var(--primary-bg-color);
    background-color: transparent !important;
}

.dark-mode .search-tabs ul li a:hover {
    background-color: transparent !important;
}

.dark-mode .tabs-menu-border ul li .active {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tabs-menu-boxed ul li a {
    color: #dedefd;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tabs-menu-boxed ul li .active {
    border-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: transparent;
}

.dark-mode .tab_wrapper .content_wrapper .accordian_header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper .content_wrapper .accordian_header .arrow {
    background: transparent;
    border-top-color: rgba(255, 255, 255, 0.3);
    border-left-color: rgba(255, 255, 255, 0.3);
}

.dark-mode .tab_wrapper .content_wrapper .accordian_header.active {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .timeline__item:after {
    background: #fff !important;
}

.dark-mode .timeline__content {
    background-color: #2a2a4a;
}

.dark-mode .timeline:before {
    background-color: #e9ecef;
}

.dark-mode .timeline-item:first-child:before,
.dark-mode .timeline-item:last-child:before {
    background: #2a2a4a;
}

.dark-mode .timeline-badge {
    border-color: #fff;
    background: #adb5bd;
}

.dark-mode .timeline-time {
    color: #9aa0ac;
}

.dark-mode .timeline__item--right .timeline__content:before {
    border-right: 12px solid rgba(238, 232, 239, 0.9);
}

.dark-mode ul.timeline:before {
    background: #d4d9df;
}

.dark-mode ul.timeline>li:before {
    border-color: #6c6c6f;
}

.dark-mode .vtimeline::before {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .vtimeline .timeline-wrapper .timeline-panel {
    background: #2a2a4a;
    box-shadow: 0 5px 12px 0 #101329;
}

.dark-mode .vtimeline .timeline-wrapper .timeline-panel:after {
    border-top: 10px solid transparent;
    border-left-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1);
    border-bottom: 10px solid transparent;
}

.dark-mode .vtimeline .timeline-wrapper .timeline-badge {
    border-color: #1a1a3c;
}

.dark-mode .vtimeline .timeline-wrapper .timeline-badge i {
    color: #fff;
}

.dark-mode .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-left-width: 0;
    border-right-width: 10px;
}

.dark-mode .timeline-wrapper-primary .timeline-panel:before,
.dark-mode .timeline-wrapper-primary .timeline-badge {
    background: var(--primary-bg-color);
}

.dark-mode .timeline-wrapper-secondary .timeline-panel:before,
.dark-mode .timeline-wrapper-secondary .timeline-badge {
    background: #05c3fb;
}

.dark-mode .timeline-wrapper-success .timeline-panel:before,
.dark-mode .timeline-wrapper-success .timeline-badge {
    background: #09ad95;
}

.dark-mode .timeline-wrapper-green .timeline-panel:before,
.dark-mode .timeline-wrapper-green .timeline-badge {
    background: #4ecc48;
}

.dark-mode .timeline-wrapper-warning .timeline-panel:before,
.dark-mode .timeline-wrapper-warning .timeline-badge {
    background: #fcd539;
}

.dark-mode .timeline-wrapper-danger .timeline-panel:before,
.dark-mode .timeline-wrapper-danger .timeline-badge {
    background: #f16d75;
}

.dark-mode .timeline-wrapper-light .timeline-panel:before,
.dark-mode .timeline-wrapper-light .timeline-badge {
    background: #2a2a4a;
}

.dark-mode .timeline-wrapper-dark .timeline-panel:before,
.dark-mode .timeline-wrapper-dark .timeline-badge {
    background: #828db1;
}

@media (max-width: 767px) {
    .dark-mode .vtimeline .timeline-wrapper .timeline-panel:after {
        border-right: 14px solid rgba(255, 255, 255, 0.2) !important;
        border-left: 0 solid rgba(255, 255, 255, 0.2) !important;
    }
}

.dark-mode .footer {
    background: transparent;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .footer .social ul li a {
    border-color: rgba(255, 255, 255, 0.1);
    background: #1a1a3c;
    color: #dedefd;
}

.dark-mode .top-footer p {
    color: #9595b5;
}

.dark-mode .top-footer a {
    color: #9595b5;
}

.dark-mode .top-footer a address {
    color: #9595b5;
}

.dark-mode .top-footer img {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .top-footer img:hover {
    color: #8e9090;
}

.dark-mode .footer-payments a {
    color: #a7a8c7;
}

.dark-mode .main-footer {
    background-color: #2a2a4a;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .header {
    background: #2a2a4a;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .header-brand,
.dark-mode .app-header .header-brand,
.dark-mode .header-brand:hover {
    color: inherit;
}

@media (max-width: 767px) {
    .dark-mode .header.hor-header {
        border-bottom-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0 8px 24px #2a2a4a;
    }

    .dark-mode .hor-header .header-brand-img.light-logo {
        margin: 0 auto;
        margin-top: 6px;
    }
}

.dark-mode .header .form-inline .form-control {
    border-color: rgba(225, 225, 225, 0.1);
    background: rgba(225, 225, 225, 0.3);
    color: #000 !important;
}

.dark-mode .header .form-inline .btn {
    border: 2px solid transparent;
    box-shadow: none;
    background: transparent;
    color: #fff;
}

@media (max-width: 767.98px) and (min-width: 576px) {
    .dark-mode .header .navsearch i {
        color: #fff;
    }

    .dark-mode .search-element .form-control {
        background: #fff !important;
        color: #000;
    }

    .dark-mode .header .form-inline .form-control::-webkit-input-placeholder {
        color: #9595b5;
    }

    .dark-mode .header .navsearch i {
        color: #fff;
    }

    .dark-mode .header .form-inline .btn {
        color: #46494a !important;
    }
}

@media only screen and (max-width: 991px) {

    .dark-mode .animated-arrow span:before,
    .dark-mode .animated-arrow span:after {
        background: #282f53;
    }

    .dark-mode .animated-arrow span:before,
    .dark-mode .animated-arrow span:after {
        background: #282f53;
    }
}

@media only screen and (max-width: 991px) {
    .dark-mode body {
        background-color: #2a2a4a;
    }
}

.dark-mode .hor-header .header-brand1 {
    color: inherit;
}

.dark-mode .header-right-icons .nav-link.icon:hover {
    background: none;
}

.dark-mode .header-right-icons .profile-user:hover {
    box-shadow: none;
}

.dark-mode .hor-header .header-right-icons .nav-link.icon {
    color: #dcdfed;
}

.dark-mode.header-light .hor-header .header-right-icons .nav-link.icon {
    color: var(--primary-bg-color) !important;
}

.dark-mode .logo-horizontal .header-brand-img.desktop-logo {
    display: block;
}

.dark-mode.horizontal.header-light .logo-horizontal .header-brand-img.light-logo1 {
    display: block;
}

.dark-mode.horizontal.header-light .logo-horizontal .header-brand-img.desktop-logo {
    display: none;
}

.dark-mode .hor-header .header-brand-img.light-logo {
    display: block;
}

@media (max-width: 992px) {
    .dark-mode .header {
        border-bottom-color: rgba(255, 255, 255, 0.2);
    }
}

.dark-mode .header .dropdown-menu {
    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .header .dropdown-menu .dropdown-item {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .header .dropdown-item,
.dark-mode .header .notifications-menu h5,
.dark-mode .header .message-menu h5 {
    color: #dedefd;
}

.dark-mode .header .notifications-menu span,
.dark-mode .header .message-menu span {
    color: #9595b5;
}

.dark-mode .header .dropdown-menu {
    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.2);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .header .dropdown-menu .dropdown-item {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .header .profile-1 .dropdown-item .dropdown-icon {
    color: var(--primary-bg-color);
}

.dark-mode .header .profile-1 .dropdown-item .dropdown-icon::after {
    background: rgba(var(--primary-bg-color), 0);
}

.dark-mode .responsive-navbar .notifications-menu h5,
.dark-mode .responsive-navbar .message-menu h5 {
    color: #dedefd;
}

.dark-mode .responsive-navbar .notifications-menu span,
.dark-mode .responsive-navbar .message-menu span {
    color: #9595b5;
}

.dark-mode .responsive-navbar .dropdown-menu {
    box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .responsive-navbar .dropdown-menu .dropdown-item {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .responsive-navbar .profile-1 .dropdown-item .dropdown-icon {
    color: var(--primary-bg-color);
}

.dark-mode .responsive-navbar .profile-1 .dropdown-item .dropdown-icon::after {
    background: rgba(var(--primary-bg-color), 0);
}

@media only screen and (max-width: 991px) {
    .dark-mode {
        /* ================== Mobile Slide Down Links CSS ================== */
        /* ================== Mobile Mega Menus CSS  ================== */
        /* ================== Mobile Header CSS ================== */
        /* Mobile Toggle Menu icon (X ICON) */
        /* ================== Mobile Overlay/Drawer CSS ================== */
        /*End Media Query*/
    }

    .dark-mode .horizontal-header {
        border-bottom-color: rgba(0, 0, 0, 0.1);
        border-top-color: rgba(0, 0, 0, 0.1);
    }

    .dark-mode .callusbtn {
        color: #a9a9a9;
    }

    .dark-mode .callusbtn:hover .fa {
        color: #a9a9a9;
    }

    .dark-mode .animated-arrow span {
        background: #9595b5;
    }

    .dark-mode .animated-arrow span:before,
    .dark-mode .animated-arrow span:after {
        background: #9595b5;
    }

    .dark-mode.active .animated-arrow span {
        background-color: transparent;
    }

    .dark-mode .horizontal-overlapbg {
        background-color: rgba(0, 0, 0, 0.45);
    }
}

.dark-mode .mega-menubg {
    background: #2a2a4a;
    box-shadow: 0 10px 40px 0 rgba(104, 113, 123, 0.1);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .horizontal-main.hor-menu {
    background: #2a2a4a;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .icons-list-item {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .icons-list-item i {
    color: #8080a1;
}

.dark-mode .browser {
    background: no-repeat center/100% 100%;
}

.dark-mode .flag,
.dark-mode .payment {
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.1);
}

.dark-mode .col-sm-3 a {
    border-color: transparent;
}

.dark-mode .col-sm-3 a:hover {
    border-color: #ff4647;
    background: linear-gradient(rgba(56, 123, 131, 0.7), rgba(56, 123, 131, 0.7));
}

.dark-mode .ui-datepicker {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ui-datepicker .ui-datepicker-header {
    color: #dedefd;
}

.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-next,
.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-prev {
    text-indent: -99999px;
    color: #6c757d;
}

.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-next:hover::before,
.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-next:focus::before {
    color: #343a40;
}

.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:hover::before,
.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-prev:focus::before {
    color: #343a40;
}

.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-next-hover,
.dark-mode .ui-datepicker .ui-datepicker-header .ui-datepicker-prev-hover {
    color: #9595b5;
}

.dark-mode .ui-datepicker .ui-datepicker-calendar th {
    color: #9595b5;
}

.dark-mode .ui-datepicker .ui-datepicker-calendar td {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ui-datepicker .ui-datepicker-calendar td span {
    background-color: #2a2a4a;
    color: #dedefd;
}

.dark-mode .ui-datepicker .ui-datepicker-calendar td a {
    background-color: #2a2a4a;
    color: #9595b5;
}

.dark-mode .ui-datepicker .ui-datepicker-calendar td a:hover {
    background-color: #2a2a4a;
    color: #dedefd;
}

.dark-mode .ui-datepicker .ui-datepicker-calendar .ui-datepicker-today a {
    background-color: #2a2a4a;
    color: #dedefd;
}

.dark-mode .jvectormap-tip {
    background: #dedefd;
    color: white;
}

.dark-mode .jvectormap-zoomin,
.dark-mode .jvectormap-zoomout,
.dark-mode .jvectormap-goback {
    background: #45456c;
    color: white;
}

.dark-mode .jvectormap-legend {
    background: black;
    color: white;
}

.dark-mode .select2-container--default .select2-selection--single {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .select2-container--default.select2-container--focus .select2-selection--multiple {
    background-color: #2a2a4a;
    border-color: #9ca3b1;
    box-shadow: none;
}

.dark-mode .select2-container--default .select2-selection--multiple {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .select2-container--default .select2-search--dropdown .select2-search__field {
    border-color: rgba(255, 255, 255, 0.1) !important;
    background: #2a2a4a;
}

.dark-mode .select2-container--default .select2-selection--multiple .select2-selection__choice,
.dark-mode .select2-container--default .select2-selection--multiple .select2-selection__choice__remove {
    color: #fff !important;
}

.dark-mode .select2-container--default .select2-results>.select2-results__options {
    box-shadow: 0px 16px 18px rgba(104, 113, 123, 0.2);
}

.dark-mode .select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #dedefd;
}

.dark-mode .select2-container--default.select2-container--disabled .select2-selection--single {
    background: #2a2a4a;
}

.dark-mode .selectgroup-button {
    border-color: rgba(255, 255, 255, 0.1);
    color: #9aa0ac;
}

.dark-mode .selectgroup-input:checked+.selectgroup-button {
    background: #2a2a4a;
}

.dark-mode .selectgroup-input:focus+.selectgroup-button {
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.dark-mode .selectize-dropdown {
    color: #495057;
}

.dark-mode .selectize-input {
    color: #495057;
    background: #2a2a4a;
}

.dark-mode .selectize-input input {
    color: #495057;
}

.dark-mode .selectize-input {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .selectize-input.full {
    background-color: #2a2a4a;
}

.dark-mode .selectize-input.focus {
    border-color: #467fcf;
    box-shadow: 0 0 0 2px rgba(98, 58, 162, 0.25);
}

.dark-mode .selectize-input.dropdown-active::before {
    background: #f0f0f0;
}

.dark-mode .selectize-dropdown {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .selectize-dropdown [data-selectable] .highlight {
    background: rgba(125, 168, 208, 0.2);
}

.dark-mode .selectize-dropdown .optgroup-header {
    color: #495057;
    background: #2a2a4a;
}

.dark-mode .selectize-dropdown .active {
    background-color: #f1f4f8;
    color: #467fcf;
}

.dark-mode .selectize-dropdown .active.create {
    color: #495057;
}

.dark-mode .selectize-dropdown .create {
    color: rgba(48, 48, 48, 0.5);
}

.dark-mode .selectize-dropdown .image img,
.dark-mode .selectize-input .image img {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.4);
}

@media (min-width: 992px) {
    .dark-mode .side-header {
        background: #2a2a4a;
    }
}

@media print {
    .dark-mode .app-content {
        background-color: #2a2a4a;
    }
}

.dark-mode .app-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .app-header__logo {
    color: #fff;
}

.dark-mode .app-sidebar__toggle {
    color: #dcdfed;
}

.dark-mode .app-sidebar__toggle:after {
    background: rgba(var(--primary-bg-color), 0);
}

.dark-mode .app-sidebar {
    color: #dedefd;
    background: #2a2a4a !important;
    -webkit-box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
    box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .app-sidebar::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.2);
}

.dark-mode .app-sidebar__user {
    color: #a8a8a8;
}

.dark-mode .app-sidebar__user img {
    box-shadow: 0 0 25px rgba(255, 255, 255, 0.1);
    border-color: rgba(255, 255, 255, 0.2);
    box-shadow: 0px 5px 5px 0px rgba(44, 44, 44, 0.2);
}

.dark-mode .app-sidebar__user-name,
.dark-mode .app-sidebar__user-designation {
    color: #e5e9ec;
}

.dark-mode .side-menu__item {
    color: #dedefd;
}

.dark-mode .side-menu__item.active {
    color: var(--primary-bg-color);
}

.dark-mode .side-menu__item.active:hover,
.dark-mode .side-menu__item.active:focus {
    color: var(--primary-bg-color);
}

.dark-mode .side-menu__item:hover,
.dark-mode .side-menu__item:focus {
    color: var(--primary-bg-color);
}

.dark-mode .side-menu__item:hover .side-menu__icon,
.dark-mode .side-menu__item:hover .side-menu__label {
    color: var(--primary-bg-color) !important;
}

.dark-mode .side-menu__item:focus .side-menu__icon,
.dark-mode .side-menu__item:focus .side-menu__label {
    color: var(--primary-bg-color) !important;
}

.dark-mode .slide-item.active,
.dark-mode .slide-item:hover,
.dark-mode .slide-item:focus {
    color: #b5c1d2;
}

.dark-mode .slide-menu a.active {
    color: var(--primary-bg-color);
}

.dark-mode .slide-item.active,
.dark-mode .slide-item:hover,
.dark-mode .slide-item:focus {
    color: var(--primary-bg-color) !important;
}

.dark-mode .sub-slide-item.active,
.dark-mode .sub-slide-item:hover,
.dark-mode .sub-slide-item:focus,
.dark-mode .sub-slide-item2.active,
.dark-mode .sub-slide-item2:hover,
.dark-mode .sub-slide-item2:focus,
.dark-mode .sub-side-menu__label.active,
.dark-mode .sub-side-menu__label:hover,
.dark-mode .sub-side-menu__label:focus,
.dark-mode .sub-side-menu__label1.active,
.dark-mode .sub-side-menu__label1:hover,
.dark-mode .sub-side-menu__label1:focus,
.dark-mode .sub-side-menu__label2.active,
.dark-mode .sub-side-menu__label2:hover,
.dark-mode .sub-side-menu__label2:focus {
    color: var(--primary-bg-color) !important;
}

.dark-mode .slide-menu li .slide-item:before {
    color: #68798b;
}

.dark-mode .side-menu .side-menu__icon {
    color: #dedefd !important;
}

.dark-mode .slide-item {
    color: #dedefd;
}

@media (min-width: 992px) {
    .dark-mode .sidebar-mini.sidenav-toggled .side-menu .side-menu__icon {
        background: none !important;
        box-shadow: none;
    }

    .dark-mode .sidebar-mini.sidenav-toggled .sidebar-mini.sidenav-toggled.user-notification::before {
        background: transparent;
    }

    .dark-mode .sidebar-mini.sidenav-toggled .app-sidebar__user {
        border-bottom-color: rgba(225, 225, 225, 0.05);
    }
}

.dark-mode .app-title {
    background-color: #2a2a4a;
    -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.dark-mode .app-breadcrumb {
    background-color: transparent;
}

.dark-mode .user-info .text-dark {
    color: #25252a !important;
}

.dark-mode .user-info .text-muted {
    color: #9595b5 !important;
}

.dark-mode .side-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .side-menu .sub-category {
    color: rgba(255, 255, 255, 0.3);
}

.dark-mode .side-menu .sub-side-menu__label,
.dark-mode .sub-slide-label,
.dark-mode .sub-side-menu__label2 {
    color: #dedefd;
}

.dark-mode .sub-slide-item,
.dark-mode .sub-slide-item2 {
    color: #dedefd;
}

.dark-mode .richText {
    border: rgba(255, 255, 255, 0.1) solid 1px;
    background-color: #2a2a4a !important;
}

.dark-mode .richText .richText-toolbar {
    border-bottom: rgba(156, 162, 161, 0) solid 1px;
}

.dark-mode .richText .richText-toolbar ul li a {
    border-right: rgba(156, 162, 161, 0.2) solid 1px;
}

.dark-mode .dark-mode .cal1 .clndr .clndr-table tr .day.event:hover,
.dark-mode .cal1 .clndr .clndr-table tr .day.my-event:hover,
.dark-mode .dark-mode .cal1 .clndr .clndr-table tr .day.today,
.dark-mode .cal1 .clndr .clndr-table tr .day.my-today {
    color: #dedefd;
}

.dark-mode .cal1 .clndr .clndr-table .header-days .header-day {
    border-left-color: rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.1);
    border-right-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .cal1 .clndr .clndr-table tr .day.event:hover,
.dark-mode .cal1 .clndr .clndr-table .cal1 .clndr .clndr-table tr .day.my-event:hover {
    color: #dedefd;
}

.dark-mode .cal1 .clndr .clndr-table tr:last-child .day,
.dark-mode .cal1 .clndr .clndr-table .cal1 .clndr .clndr-table tr:last-child .my-day {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .cal1 .clndr .clndr-table tr .empty,
.dark-mode .cal1 .clndr .clndr-table tr .adjacent-month,
.dark-mode .cal1 .clndr .clndr-table tr .my-empty,
.dark-mode .cal1 .clndr .clndr-table tr .my-adjacent-month {
    border-left-color: rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.1);
    color: #9595b5;
}

.dark-mode .cal1 .clndr .clndr-table tr .day {
    border-left-color: rgba(255, 255, 255, 0.1);
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .cal1 .clndr .clndr-table tr .day.event,
.dark-mode .cal1 .clndr .clndr-table tr .day.my-event {
    background: #252548 !important;
}

.dark-mode .cal1 .clndr .clndr-table tr .day:last-child {
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .cal1 .clndr .clndr-table tr .day:hover {
    background: #2e2e4a;
}

.dark-mode .cal1 .clndr .clndr-controls {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: transparent;
}

.dark-mode .cal1 .clndr .clndr-controls .clndr-control-button .clndr-previous-button,
.dark-mode .cal1 .clndr .clndr-controls .clndr-control-button .clndr-next-button {
    color: #fff;
}

.dark-mode .fc-unthemed .fc-content,
.dark-mode .fc-unthemed .fc-divider,
.dark-mode .fc-unthemed .fc-list-heading td,
.dark-mode .fc-unthemed .fc-list-view,
.dark-mode .fc-unthemed .fc-popover,
.dark-mode .fc-unthemed .fc-row,
.dark-mode .fc-unthemed tbody,
.dark-mode .fc-unthemed td,
.dark-mode .fc-unthemed th,
.dark-mode .fc-unthemed thead {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .fc-event,
.dark-mode .fc-event-dot {
    color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .fc-unthemed .fc-divider,
.dark-mode .fc-unthemed .fc-list-heading td,
.dark-mode .fc-unthemed .fc-popover .fc-header {
    background: rgba(255, 255, 255, 0.1);
}

.dark-mode .fc-toolbar .fc-state-active,
.dark-mode .fc-toolbar .ui-state-active {
    background: #b4b4b4;
}

.dark-mode .fc-today-button fc-button fc-state-default fc-corner-left fc-corner-right fc-state-disabled:focus {
    border: none !important;
    box-shadow: none !important;
}

.dark-mode .fc-unthemed .fc-list-item:hover td {
    background-color: #eeeeee;
}

.dark-mode .cal1 .clndr .clndr-table tr .empty:hover,
.dark-mode .cal1 .clndr .clndr-table tr .adjacent-month:hover,
.dark-mode .cal1 .clndr .clndr-table tr .my-empty:hover,
.dark-mode .cal1 .clndr .clndr-table tr .my-adjacent-month:hover {
    background: #2f2f50;
}

.dark-mode .instagram {
    background: linear-gradient(to right bottom, #de497b 0%, #e1164f 100%);
}

.dark-mode .linkedin {
    background-image: linear-gradient(to right bottom, #0d97de 0%, #13547a 100%);
}

.dark-mode .twitter {
    background-image: linear-gradient(to right bottom, #00f2fe 0%, #1e63c3 100%);
}

.dark-mode .facebook {
    background-image: linear-gradient(to right bottom, #3d6cbf 0%, #1e3c72 100%);
}

.dark-mode .map-header:before {
    background: linear-gradient(to bottom, rgba(245, 247, 251, 0) 5%, #2a2a4a 95%);
}

.dark-mode .chart-dropshadow {
    -webkit-filter: drop-shadow(-6px 5px 4px #2a2635);
    filter: drop-shadow(-6px 5px 4px #2a2635);
}

.dark-mode .chart-dropshadow-primary {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
    filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
}

.dark-mode .chart-dropshadow-primary-1 {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
    filter: drop-shadow(-6px 12px 4px rgba(133, 67, 246, 0.2));
}

.dark-mode .chart-dropshadow-danger {
    -webkit-filter: drop-shadow(-6px 12px 4px rgba(244, 88, 91, 0.1));
    filter: drop-shadow(-6px 5px 4px rgba(244, 88, 91, 0.1));
}

.dark-mode .chart-dropshadow-warning {
    -webkit-filter: drop-shadow(-6px 5px 4px rgba(247, 183, 49, 0.1));
    filter: drop-shadow(-6px 5px 4px rgba(247, 183, 49, 0.1));
}

.dark-mode .BarChartShadow {
    -webkit-filter: drop-shadow(-4px 9px 4px rgba(0, 0, 0, 0.3));
    filter: drop-shadow(-6px 9px 4px rgba(0, 0, 0, 0.3));
}

.dark-mode .chart-dropshadow2 {
    -webkit-filter: drop-shadow(-6px 5px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(-6px 5px 4px rgba(0, 0, 0, 0.2));
}

.dark-mode .chart-dropshadow-secondary {
    -webkit-filter: drop-shadow(-6px 5px 4px rgba(130, 207, 242, 0.1));
    filter: drop-shadow(-6px 5px 4px rgba(130, 207, 242, 0.1));
}

.dark-mode .chart-dropshadow-success {
    -webkit-filter: drop-shadow(-6px 5px 4px rgba(19, 191, 166, 0.1));
    filter: drop-shadow(-6px 5px 4px rgba(19, 191, 166, 0.1));
}

.dark-mode .chart-dropshadow-info {
    -webkit-filter: drop-shadow(-6px 5px 4px rgba(7, 116, 248, 0.1));
    filter: drop-shadow(-6px 5px 4px rgba(7, 116, 248, 0.1));
}

.dark-mode .donutShadow {
    -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(-1px 0px 2px rgba(159, 120, 255, 0.5));
}

.dark-mode .donutShadow-yellow {
    -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(-1px 0px 2px rgba(251, 196, 52, 0.5));
}

.dark-mode .donutShadow-blue {
    -webkit-filter: drop-shadow(-5px 4px 6px rgba(0, 0, 0, 0.5));
    filter: drop-shadow(-1px 0px 2px rgba(36, 72, 135, 0.5));
}

.dark-mode .main-chat-contacts-wrapper {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-list .media {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-list .media+.media {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-list .media.new {
    background-color: #2a2a4a;
}

.dark-mode .main-chat-list .media.new .media-contact-name span:first-child {
    color: #dedefd;
}

.dark-mode .main-chat-list .media.new .media-body p {
    color: #8f9cc0;
}

.dark-mode .main-chat-list .media:hover,
.dark-mode .main-chat-list .media:focus {
    background-color: #2a2a4a;
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-list .media:hover:first-child,
.dark-mode .main-chat-list .media:focus:first-child {
    border-top-color: transparent;
}

.dark-mode .main-chat-list .media.selected {
    background-color: #252544;
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-list .media.selected:first-child {
    border-top-color: transparent;
}

.dark-mode .main-chat-list .media.selected .media-contact-name span:first-child {
    color: #dedefd;
}

.dark-mode .main-chat-list .media.selected .media-body p {
    color: #8f9cc0;
}

.dark-mode .main-chat-list .main-img-user span {
    color: #fff;
    background-color: #05c3fb;
    box-shadow: 0 0 0 2px #2a2a4a;
}

.dark-mode .main-chat-list .media-body p {
    color: #9595b5;
}

.dark-mode .main-chat-list .media-contact-name span:first-child {
    color: #dedefd;
}

.dark-mode .main-chat-list .media-contact-name span:last-child {
    color: #9595b5;
}

.dark-mode .main-chat-header {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-header .nav-link {
    color: #9595b5;
}

.dark-mode .main-chat-msg-name small,
.dark-mode .main-chat-body .media-body>div:last-child {
    color: #9595b5;
}

.dark-mode .main-chat-time span {
    background: #2a2a4a;
}

.dark-mode .main-chat-time::before,
.dark-mode .main-chat-time::after {
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-footer {
    border-top-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
}

.dark-mode .main-chat-footer .nav-link {
    color: #9595b5;
}

.dark-mode .main-chat-footer .form-control {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-footer .form-control:hover,
.dark-mode .main-chat-footer .form-control:focus {
    box-shadow: none;
}

.dark-mode .main-content-title {
    color: #170c6b;
}

.dark-mode .main-msg-wrapper {
    background-color: #1a1a3c;
}

.dark-mode .main-chat-body .media.flex-row-reverse .main-msg-wrapper {
    background-color: #4b4b66;
    color: #dedefd;
}

.dark-mode .chat-profile {
    color: #9595b5;
}

.dark-mode .shared-files {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .main-chat-list .media:hover,
.dark-mode .main-chat-list .media:focus {
    background: #252544 !important;
}

.dark-mode .authentication .card:hover {
    box-shadow: 0 16px 26px 0 rgba(0, 0, 0, 0.4), 0 3px 6px 0 rgba(0, 0, 0, 0.4);
}

.dark-mode .authentication .form-control:focus {
    box-shadow: none;
}

.dark-mode .authentication input::placeholder {
    color: #9595b5;
}

.dark-mode .wrap-login100 {
    background: #2a2a4a;
    box-shadow: 0 3px 9px 0 rgba(28, 28, 51, 0.15);
}

.dark-mode .login100-form-title {
    color: #dedefd;
}

.dark-mode .input100 {
    color: #dedefd;
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .symbol-input100,
.dark-mode .wrap-input100 input::-webkit-input-placeholder {
    color: #9595b5;
}

.dark-mode .construction .btn.btn-icon {
    background: rgba(255, 255, 255, 0.08);
    color: #fff;
}

.dark-mode .range {
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    background-color: whitesmoke;
    -webkit-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}

.dark-mode .range input[type=range] {
    background-color: transparent;
}

.dark-mode .range input[type=range]::-webkit-slider-thumb,
.dark-mode .range input[type=range]::-moz-slider-thumb {
    color: white;
    background-color: #999999;
}

.dark-mode .range output {
    color: white;
    background-color: #999999;
}

.dark-mode .range.range-success input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-success input[type=range]::-moz-slider-thumb {
    background-color: #4ecc48;
}

.dark-mode .range.range-success output {
    background-color: #4ecc48;
}

.dark-mode .range.range-success input[type=range] {
    outline-color: #4ecc48;
}

.dark-mode .range.range-info input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-info input[type=range]::-moz-slider-thumb {
    background-color: #45aaf2;
}

.dark-mode .range.range-info output {
    background-color: #45aaf2;
}

.dark-mode .range.range-info input[type=range] {
    outline-color: #45aaf2;
}

.dark-mode .range.range-warning input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-warning input[type=range]::-moz-slider-thumb {
    background-color: #ecb403;
}

.dark-mode .range.range-warning output {
    background-color: #ecb403;
}

.dark-mode .range.range-warning input[type=range] {
    outline-color: #ecb403;
}

.dark-mode .range.range-danger input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-danger input[type=range]::-moz-slider-thumb {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger output {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger input[type=range] {
    outline-color: #c21a1a;
}

.dark-mode .range input[type=range] {
    background-color: transparent;
}

.dark-mode .range input[type=range]::-webkit-slider-thumb,
.dark-mode .range input[type=range]::-moz-slider-thumb {
    color: white;
    background-color: #999999;
}

.dark-mode .range output {
    color: white;
    background-color: #999999;
}

.dark-mode .range.range-success input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-success input[type=range]::-moz-slider-thumb {
    background-color: #4ecc48;
}

.dark-mode .range.range-success output {
    background-color: #4ecc48;
}

.dark-mode .range.range-success input[type=range] {
    outline-color: #4ecc48;
}

.dark-mode .range.range-info input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-info input[type=range]::-moz-slider-thumb {
    background-color: #45aaf2;
}

.dark-mode .range.range-info output {
    background-color: #45aaf2;
}

.dark-mode .range.range-info input[type=range] {
    outline-color: #45aaf2;
}

.dark-mode .range.range-warning input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-warning input[type=range]::-moz-slider-thumb {
    background-color: #ecb403;
}

.dark-mode .range.range-warning output {
    background-color: #ecb403;
}

.dark-mode .range.range-warning input[type=range] {
    outline-color: #ecb403;
}

.dark-mode .range.range-danger input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-danger input[type=range]::-moz-slider-thumb {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger output {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger input[type=range] {
    outline-color: #c21a1a;
}

.dark-mode .range input[type=range] {
    background-color: transparent;
}

.dark-mode .range input[type=range]::-webkit-slider-thumb,
.dark-mode .range input[type=range]::-moz-slider-thumb {
    color: white;
    background-color: #999999;
}

.dark-mode .range output {
    color: white;
    background-color: #999999;
}

.dark-mode .range.range-success input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-success input[type=range]::-moz-slider-thumb {
    background-color: #4ecc48;
}

.dark-mode .range.range-success output {
    background-color: #4ecc48;
}

.dark-mode .range.range-success input[type=range] {
    outline-color: #4ecc48;
}

.dark-mode .range.range-info input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-info input[type=range]::-moz-slider-thumb {
    background-color: #45aaf2;
}

.dark-mode .range.range-info output {
    background-color: #45aaf2;
}

.dark-mode .range.range-info input[type=range] {
    outline-color: #45aaf2;
}

.dark-mode .range.range-warning input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-warning input[type=range]::-moz-slider-thumb {
    background-color: #ecb403;
}

.dark-mode .range.range-warning output {
    background-color: #ecb403;
}

.dark-mode .range.range-warning input[type=range] {
    outline-color: #ecb403;
}

.dark-mode .range.range-danger input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-danger input[type=range]::-moz-slider-thumb {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger output {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger input[type=range] {
    outline-color: #c21a1a;
}

.dark-mode .range input[type=range] {
    background-color: transparent;
}

.dark-mode .range input[type=range]::-webkit-slider-thumb,
.dark-mode .range input[type=range]::-moz-slider-thumb {
    color: white;
    background-color: #999999;
}

.dark-mode .range output {
    color: white;
    background-color: #999999;
}

.dark-mode .range.range-success input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-success input[type=range]::-moz-slider-thumb {
    background-color: #4ecc48;
}

.dark-mode .range.range-success output {
    background-color: #4ecc48;
}

.dark-mode .range.range-success input[type=range] {
    outline-color: #4ecc48;
}

.dark-mode .range.range-info input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-info input[type=range]::-moz-slider-thumb {
    background-color: #45aaf2;
}

.dark-mode .range.range-info output {
    background-color: #45aaf2;
}

.dark-mode .range.range-info input[type=range] {
    outline-color: #45aaf2;
}

.dark-mode .range.range-warning input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-warning input[type=range]::-moz-slider-thumb {
    background-color: #ecb403;
}

.dark-mode .range.range-warning output {
    background-color: #ecb403;
}

.dark-mode .range.range-warning input[type=range] {
    outline-color: #ecb403;
}

.dark-mode .range.range-danger input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-danger input[type=range]::-moz-slider-thumb {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger output {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger input[type=range] {
    outline-color: #c21a1a;
}

.dark-mode .range input[type=range] {
    background-color: transparent;
}

.dark-mode .range input[type=range]::-webkit-slider-thumb,
.dark-mode .range input[type=range]::-moz-slider-thumb {
    color: white;
    background-color: #999999;
}

.dark-mode .range output {
    color: white;
    background-color: #999999;
}

.dark-mode .range.range-success input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-success input[type=range]::-moz-slider-thumb {
    background-color: #4ecc48;
}

.dark-mode .range.range-success output {
    background-color: #4ecc48;
}

.dark-mode .range.range-success input[type=range] {
    outline-color: #4ecc48;
}

.dark-mode .range.range-info input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-info input[type=range]::-moz-slider-thumb {
    background-color: #45aaf2;
}

.dark-mode .range.range-info output {
    background-color: #45aaf2;
}

.dark-mode .range.range-info input[type=range] {
    outline-color: #45aaf2;
}

.dark-mode .range.range-warning input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-warning input[type=range]::-moz-slider-thumb {
    background-color: #ecb403;
}

.dark-mode .range.range-warning output {
    background-color: #ecb403;
}

.dark-mode .range.range-warning input[type=range] {
    outline-color: #ecb403;
}

.dark-mode .range.range-danger input[type=range]::-webkit-slider-thumb,
.dark-mode .range.range-danger input[type=range]::-moz-slider-thumb {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger output {
    background-color: #c21a1a;
}

.dark-mode .range.range-danger input[type=range] {
    outline-color: #c21a1a;
}

.dark-mode .ribbon1 {
    color: #fff;
}

.dark-mode .ribbon1:after {
    border-left: 20px solid transparent;
    border-right: 24px solid transparent;
    border-top: 13px solid #f8463f;
}

.dark-mode .ribbon1 span {
    background: #f8463f;
}

.dark-mode .ribbon1 span:before {
    background: #f8463f;
}

.dark-mode .ribbon1 span:after {
    background: #c02031;
}

.dark-mode .ribbon span {
    color: #fff;
    background: #79a70a;
    background: linear-gradient(#f8463f 0%, #f8463f 100%);
    box-shadow: 0 3px 10px -5px black;
}

.dark-mode .ribbon span::before {
    border-left: 3px solid #f8463f;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #f8463f;
}

.dark-mode .ribbon span::after {
    border-left: 3px solid transparent;
    border-right: 3px solid #f8463f;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #f8463f;
}

.dark-mode .wizard {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
}

.dark-mode .wizard>.steps a {
    color: var(--primary-bg-color);
}

.dark-mode .wizard>.steps a:hover,
.dark-mode .wizard>.steps a:active {
    color: var(--primary-bg-color);
}

.dark-mode .wizard>.steps a .number,
.dark-mode .wizard>.steps a:hover .number,
.dark-mode .wizard>.steps a:active .number {
    background-color: #ededf3;
}

.dark-mode .wizard>.steps .disabled a {
    color: #696e8d;
}

.dark-mode .wizard>.steps .disabled a:hover,
.dark-mode .wizard>.steps .disabled a:active {
    color: #696e8d;
}

.dark-mode .wizard>.steps .current a {
    color: var(--primary-bg-color);
}

.dark-mode .wizard>.steps .current a:hover,
.dark-mode .wizard>.steps .current a:active {
    color: var(--primary-bg-color);
}

.dark-mode .wizard>.steps .current a .number,
.dark-mode .wizard>.steps .current a:hover .number,
.dark-mode .wizard>.steps .current a:active .number {
    background-color: var(--primary-bg-color);
    color: #fff;
}

.dark-mode .wizard>.steps .done a {
    color: #09ad95;
}

.dark-mode .wizard>.steps .done a:hover,
.dark-mode .wizard>.steps .done a:active {
    color: #09ad95;
}

.dark-mode .wizard>.steps .done a .number,
.dark-mode .wizard>.steps .done a:hover .number,
.dark-mode .wizard>.steps .done a:active .number {
    background-color: #09ad95;
    color: #fff;
}

.dark-mode .wizard>.content {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .wizard>.content>.title {
    color: #282f53;
}

.dark-mode .wizard>.content>.body input.parsley-error {
    border-color: #ff5c77;
}

.dark-mode .wizard>.actions>ul>li:last-child a {
    background-color: #09ad95;
}

.dark-mode .wizard>.actions a {
    background-color: var(--primary-bg-color);
    color: #fff;
}

.dark-mode .wizard>.actions a:hover,
.dark-mode .wizard>.actions a:active {
    background-color: var(--primary-bg-color);
    color: #fff;
}

.dark-mode .wizard>.actions .disabled a {
    background-color: #e8ebf2;
    color: #282f53;
}

.dark-mode .wizard>.actions .disabled a:hover,
.dark-mode .wizard>.actions .disabled a:active {
    background-color: #e8ebf2;
    color: #282f53;
}

@media (min-width: 576px) {
    .dark-mode .wizard.vertical>.content {
        border-left-color: rgba(255, 255, 255, 0.1);
        border-right-color: rgba(255, 255, 255, 0.1);
    }
}

@media (min-width: 576px) {
    .dark-mode .wizard.vertical>.actions {
        border-left-color: rgba(255, 255, 255, 0.1);
        border-right-color: rgba(255, 255, 255, 0.1);
    }
}

.dark-mode .wizard-style-1>.steps>ul a .number,
.dark-mode .wizard-style-1>.steps>ul a:hover .number,
.dark-mode .wizard-style-1>.steps>ul a:active .number {
    color: #696e8d;
    background-color: #f3f7fd;
}

.dark-mode .wizard-style-1>.steps>ul .current a .number,
.dark-mode .wizard-style-1>.steps>ul .current a:hover .number,
.dark-mode .wizard-style-1>.steps>ul .current a:active .number {
    background-color: var(--primary-bg-color);
    color: #fff;
}

.dark-mode .wizard-style-1>.steps>ul .done a .number,
.dark-mode .wizard-style-1>.steps>ul .done a:hover .number,
.dark-mode .wizard-style-1>.steps>ul .done a:active .number {
    background-color: #643ab0;
    color: #fff;
}

.dark-mode .wizard-style-2>.steps>ul a .number,
.dark-mode .wizard-style-2>.steps>ul a:hover .number,
.dark-mode .wizard-style-2>.steps>ul a:active .number {
    border: 2px solid #f3f7fd;
    color: #696e8d;
    background-color: #2a2a4a;
}

.dark-mode .wizard-style-2>.steps>ul .current a .number,
.dark-mode .wizard-style-2>.steps>ul .current a:hover .number,
.dark-mode .wizard-style-2>.steps>ul .current a:active .number {
    border-color: var(--primary-bg-color);
    color: var(--primary-bg-color);
}

.dark-mode .wizard-style-2>.steps>ul .done a .number,
.dark-mode .wizard-style-2>.steps>ul .done a:hover .number,
.dark-mode .wizard-style-2>.steps>ul .done a:active .number {
    border-color: #8c3feb;
    color: #8c3feb;
}

.dark-mode .parsley-required {
    color: #ff5c77;
}

.dark-mode .wizard-card .moving-tab {
    background-color: var(--primary-bg-color) !important;
}

.dark-mode .form-group label.control-label {
    color: var(--primary-bg-color);
}

.dark-mode .wizard-card.form-group .form-control {
    background-image: linear-gradient(#c4c4c4, #c4c4c4, linear-gradient(#e1e1e2, #e1e1e2));
}

.dark-mode .wizard-container .wizard-navigation {
    background: #ebeff8;
}

.dark-mode .wizard-card .choice:hover .icon,
.dark-mode .wizard-card .choice.active .icon {
    border-color: var(--primary-bg-color) !important;
    color: var(--primary-bg-color) !important;
}

.dark-mode .widgets-cards .wrp.icon-circle i {
    color: #fff;
}

.dark-mode .bg-secondary {
    background: #05c3fb !important;
}

.dark-mode a.bg-secondary:hover,
.dark-mode a.bg-secondary:focus {
    background-color: #05c3fb !important;
}

.dark-mode button.bg-secondary:hover,
.dark-mode button.bg-secondary:focus {
    background-color: #05c3fb !important;
}

.dark-mode .bg-success {
    background: #09ad95 !important;
}

.dark-mode a.bg-success:hover,
.dark-mode a.bg-success:focus {
    background-color: #15bf42 !important;
}

.dark-mode button.bg-success:hover,
.dark-mode button.bg-success:focus {
    background-color: #15bf42 !important;
}

.dark-mode .bg-info {
    background: #1170e4 !important;
}

.dark-mode a.bg-info:hover,
.dark-mode a.bg-info:focus {
    background-color: #1eb0e2 !important;
}

.dark-mode button.bg-info:hover,
.dark-mode button.bg-info:focus {
    background-color: #1eb0e2 !important;
}

.dark-mode .bg-warning {
    background: #f7b731 !important;
}

.dark-mode a.bg-warning:hover,
.dark-mode a.bg-warning:focus {
    background-color: #e0a325 !important;
}

.dark-mode button.bg-warning:hover,
.dark-mode button.bg-warning:focus {
    background-color: #e0a325 !important;
}

.dark-mode .bg-danger {
    background: #e82646 !important;
}

.dark-mode a.bg-danger:hover,
.dark-mode a.bg-danger:focus {
    background-color: #de223d !important;
}

.dark-mode button.bg-danger:hover,
.dark-mode button.bg-danger:focus {
    background-color: #de223d !important;
}

.dark-mode .bg-light {
    background-color: #1a1a3c !important;
}

.dark-mode a.bg-light:hover,
.dark-mode a.bg-light:focus {
    background-color: #2a2a4a !important;
}

.dark-mode button.bg-light:hover,
.dark-mode button.bg-light:focus {
    background-color: #2a2a4a !important;
}

.dark-mode .bg-dark {
    background-color: #343a40 !important;
}

.dark-mode a.bg-dark:hover,
.dark-mode a.bg-dark:focus {
    background-color: #1d2124 !important;
}

.dark-mode button.bg-dark:hover,
.dark-mode button.bg-dark:focus {
    background-color: #1d2124 !important;
}

.dark-mode .bg-facebook {
    background: #2b4170 !important;
}

.dark-mode .bg-secondary-gradient {
    background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%) !important;
}

.dark-mode a.bg-secondary-gradient:hover,
.dark-mode a.bg-secondary-gradient:focus {
    background-color: #05c3fb !important;
}

.dark-mode button.bg-secondary-gradient:hover,
.dark-mode button.bg-secondary-gradient:focus {
    background-color: #05c3fb !important;
}

.dark-mode .bg-success-gradient {
    background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%) !important;
}

.dark-mode a.bg-success-gradient:hover,
.dark-mode a.bg-success-gradient:focus {
    background-color: #448700 !important;
}

.dark-mode button.bg-success-gradient:hover,
.dark-mode button.bg-success-gradient:focus {
    background-color: #448700 !important;
}

.dark-mode .bg-info-gradient {
    background: linear-gradient(to bottom right, #1e63c3 0%, #00f2fe 100%) !important;
}

.dark-mode a.bg-info-gradient:hover,
.dark-mode a.bg-info-gradient:focus {
    background-color: #1594ef !important;
}

.dark-mode button.bg-info-gradient:hover,
.dark-mode button.bg-info-gradient:focus {
    background-color: #1594ef !important;
}

.dark-mode .bg-warning-gradient {
    background: linear-gradient(to bottom right, #f66b4e 0%, #fbc434 100%) !important;
}

.dark-mode a.bg-warning-gradient:hover,
.dark-mode a.bg-warning-gradient:focus {
    background-color: #FBB034 !important;
}

.dark-mode button.bg-warning-gradient:hover,
.dark-mode button.bg-warning-gradient:focus {
    background-color: #FBB034 !important;
}

.dark-mode .bg-danger-gradient {
    background-image: linear-gradient(to bottom right, #b51b35 0%, #fd4a68 100%) !important;
}

.dark-mode a.bg-danger-gradient:hover,
.dark-mode a.bg-danger-gradient:focus {
    background-color: #a11918 !important;
}

.dark-mode button.bg-danger-gradient:hover,
.dark-mode button.bg-danger-gradient:focus {
    background-color: #a11918 !important;
}

.dark-mode .bg-light-gradient {
    background-color: #f8f9fa !important;
}

.dark-mode a.bg-light-gradient:hover,
.dark-mode a.bg-light-gradient:focus {
    background-color: #dae0e5 !important;
}

.dark-mode button.bg-light-gradient:hover,
.dark-mode button.bg-light-gradient:focus {
    background-color: #dae0e5 !important;
}

.dark-mode .bg-dark-gradient {
    background-color: #343a40 !important;
}

.dark-mode a.bg-dark-gradient:hover,
.dark-mode a.bg-dark-gradient:focus {
    background-color: #1d2124 !important;
}

.dark-mode button.bg-dark-gradient:hover,
.dark-mode button.bg-dark-gradient:focus {
    background-color: #1d2124 !important;
}

.dark-mode .bg-facebook-gradient {
    background: linear-gradient(to bottom right, #3b5998, #2b4170) !important;
}

.dark-mode .bg-white {
    background-color: #1a1a3c !important;
}

.dark-mode .bg-transparent {
    background-color: transparent !important;
}

.dark-mode .bg1 {
    background: linear-gradient(to right bottom, #163b7c 0%, #548beb 100%);
}

.dark-mode .bg2 {
    background: linear-gradient(to bottom right, #00f2fe 0%, #1e63c3 100%) !important;
}

.dark-mode .bg3 {
    background: linear-gradient(to bottom right, #f53e31, #dd4b39);
}

.dark-mode .bg-purple {
    background: #8927ec !important;
    color: #fff !important;
}

.dark-mode a.bg-purple:hover,
.dark-mode a.bg-purple:focus {
    background-color: #8c31e4 !important;
}

.dark-mode button.bg-purple:hover,
.dark-mode button.bg-purple:focus {
    background-color: #8c31e4 !important;
}

.dark-mode .bg-blue-lightest {
    background-color: #edf2fa !important;
}

.dark-mode a.bg-blue-lightest:hover,
.dark-mode a.bg-blue-lightest:focus {
    background-color: #c5d5ef !important;
}

.dark-mode button.bg-blue-lightest:hover,
.dark-mode button.bg-blue-lightest:focus {
    background-color: #c5d5ef !important;
}

.dark-mode .bg-blue-lighter {
    background-color: #c8d9f1 !important;
}

.dark-mode a.bg-blue-lighter:hover,
.dark-mode a.bg-blue-lighter:focus {
    background-color: #9fbde7 !important;
}

.dark-mode button.bg-blue-lighter:hover,
.dark-mode button.bg-blue-lighter:focus {
    background-color: #9fbde7 !important;
}

.dark-mode .bg-blue-light {
    background-color: #7ea5dd !important;
}

.dark-mode a.bg-blue-light:hover,
.dark-mode a.bg-blue-light:focus {
    background-color: #5689d2 !important;
}

.dark-mode button.bg-blue-light:hover,
.dark-mode button.bg-blue-light:focus {
    background-color: #5689d2 !important;
}

.dark-mode .bg-blue-dark {
    background-color: #3866a6 !important;
}

.dark-mode a.bg-blue-dark:hover,
.dark-mode a.bg-blue-dark:focus {
    background-color: #2b4f80 !important;
}

.dark-mode button.bg-blue-dark:hover,
.dark-mode button.bg-blue-dark:focus {
    background-color: #2b4f80 !important;
}

.dark-mode .bg-blue-darker {
    background-color: #1c3353 !important;
}

.dark-mode a.bg-blue-darker:hover,
.dark-mode a.bg-blue-darker:focus {
    background-color: #0f1c2d !important;
}

.dark-mode button.bg-blue-darker:hover,
.dark-mode button.bg-blue-darker:focus {
    background-color: #0f1c2d !important;
}

.dark-mode .bg-blue-darkest {
    background-color: #0e1929 !important;
}

.dark-mode a.bg-blue-darkest:hover,
.dark-mode a.bg-blue-darkest:focus {
    background-color: #010203 !important;
}

.dark-mode button.bg-blue-darkest:hover,
.dark-mode button.bg-blue-darkest:focus {
    background-color: #010203 !important;
}

.dark-mode .bg-purssianblue {
    background-color: #362f71;
}

.dark-mode a.bg-purssianblue-lightest:hover,
.dark-mode a.bg-purssianblue-lightest:focus {
    background-color: #3f3688 !important;
}

.dark-mode button.bg-purssianblue-lightest:hover,
.dark-mode button.bg-purssianblue-lightest:focus {
    background-color: #3f3688 !important;
}

.dark-mode .bg-indigo-lightest {
    background-color: #f0f1fa !important;
}

.dark-mode a.bg-indigo-lightest:hover,
.dark-mode a.bg-indigo-lightest:focus {
    background-color: #cacded !important;
}

.dark-mode button.bg-indigo-lightest:hover,
.dark-mode button.bg-indigo-lightest:focus {
    background-color: #cacded !important;
}

.dark-mode .bg-indigo-lighter {
    background-color: #d1d5f0 !important;
}

.dark-mode a.bg-indigo-lighter:hover,
.dark-mode a.bg-indigo-lighter:focus {
    background-color: #abb2e3 !important;
}

.dark-mode button.bg-indigo-lighter:hover,
.dark-mode button.bg-indigo-lighter:focus {
    background-color: #abb2e3 !important;
}

.dark-mode .bg-indigo-light {
    background-color: #939edc !important;
}

.dark-mode a.bg-indigo-light:hover,
.dark-mode a.bg-indigo-light:focus {
    background-color: #6c7bd0 !important;
}

.dark-mode button.bg-indigo-light:hover,
.dark-mode button.bg-indigo-light:focus {
    background-color: #6c7bd0 !important;
}

.dark-mode .bg-indigo-dark {
    background-color: #515da4 !important;
}

.dark-mode a.bg-indigo-dark:hover,
.dark-mode a.bg-indigo-dark:focus {
    background-color: #404a82 !important;
}

.dark-mode button.bg-indigo-dark:hover,
.dark-mode button.bg-indigo-dark:focus {
    background-color: #404a82 !important;
}

.dark-mode .bg-indigo-darker {
    background-color: #282e52 !important;
}

.dark-mode a.bg-indigo-darker:hover,
.dark-mode a.bg-indigo-darker:focus {
    background-color: #171b30 !important;
}

.dark-mode button.bg-indigo-darker:hover,
.dark-mode button.bg-indigo-darker:focus {
    background-color: #171b30 !important;
}

.dark-mode .bg-indigo-darkest {
    background-color: #141729 !important;
}

.dark-mode a.bg-indigo-darkest:hover,
.dark-mode a.bg-indigo-darkest:focus {
    background-color: #030407 !important;
}

.dark-mode button.bg-indigo-darkest:hover,
.dark-mode button.bg-indigo-darkest:focus {
    background-color: #030407 !important;
}

.dark-mode .bg-purple-lightest {
    background-color: #f6effd !important;
}

.dark-mode a.bg-purple-lightest:hover,
.dark-mode a.bg-purple-lightest:focus {
    background-color: #ddc2f7 !important;
}

.dark-mode button.bg-purple-lightest:hover,
.dark-mode button.bg-purple-lightest:focus {
    background-color: #ddc2f7 !important;
}

.dark-mode .bg-purple-lighter {
    background-color: #e4cff9 !important;
}

.dark-mode a.bg-purple-lighter:hover,
.dark-mode a.bg-purple-lighter:focus {
    background-color: #cba2f3 !important;
}

.dark-mode button.bg-purple-lighter:hover,
.dark-mode button.bg-purple-lighter:focus {
    background-color: #cba2f3 !important;
}

.dark-mode .bg-purple-light {
    background-color: #c08ef0 !important;
}

.dark-mode a.bg-purple-light:hover,
.dark-mode a.bg-purple-light:focus {
    background-color: #a761ea !important;
}

.dark-mode button.bg-purple-light:hover,
.dark-mode button.bg-purple-light:focus {
    background-color: #a761ea !important;
}

.dark-mode .bg-purple-dark {
    background-color: #844bbb !important;
}

.dark-mode a.bg-purple-dark:hover,
.dark-mode a.bg-purple-dark:focus {
    background-color: #6a3a99 !important;
}

.dark-mode button.bg-purple-dark:hover,
.dark-mode button.bg-purple-dark:focus {
    background-color: #6a3a99 !important;
}

.dark-mode .bg-purple-darker {
    background-color: #42265e !important;
}

.dark-mode a.bg-purple-darker:hover,
.dark-mode a.bg-purple-darker:focus {
    background-color: #29173a !important;
}

.dark-mode button.bg-purple-darker:hover,
.dark-mode button.bg-purple-darker:focus {
    background-color: #29173a !important;
}

.dark-mode .bg-purple-darkest {
    background-color: #21132f !important;
}

.dark-mode a.bg-purple-darkest:hover,
.dark-mode a.bg-purple-darkest:focus {
    background-color: #08040b !important;
}

.dark-mode button.bg-purple-darkest:hover,
.dark-mode button.bg-purple-darkest:focus {
    background-color: #08040b !important;
}

.dark-mode .bg-pink-lightest {
    background-color: #fef0f5 !important;
}

.dark-mode a.bg-pink-lightest:hover,
.dark-mode a.bg-pink-lightest:focus {
    background-color: #fbc0d5 !important;
}

.dark-mode button.bg-pink-lightest:hover,
.dark-mode button.bg-pink-lightest:focus {
    background-color: #fbc0d5 !important;
}

.dark-mode .bg-pink-lighter {
    background-color: #fcd3e1 !important;
}

.dark-mode a.bg-pink-lighter:hover,
.dark-mode a.bg-pink-lighter:focus {
    background-color: #f9a3c0 !important;
}

.dark-mode button.bg-pink-lighter:hover,
.dark-mode button.bg-pink-lighter:focus {
    background-color: #f9a3c0 !important;
}

.dark-mode .bg-pink-light {
    background-color: #f999b9 !important;
}

.dark-mode a.bg-pink-light:hover,
.dark-mode a.bg-pink-light:focus {
    background-color: #f66998 !important;
}

.dark-mode button.bg-pink-light:hover,
.dark-mode button.bg-pink-light:focus {
    background-color: #f66998 !important;
}

.dark-mode .bg-pink-dark {
    background-color: #c5577c !important;
}

.dark-mode a.bg-pink-dark:hover,
.dark-mode a.bg-pink-dark:focus {
    background-color: #ad3c62 !important;
}

.dark-mode button.bg-pink-dark:hover,
.dark-mode button.bg-pink-dark:focus {
    background-color: #ad3c62 !important;
}

.dark-mode .bg-pink-darker {
    background-color: #622c3e !important;
}

.dark-mode a.bg-pink-darker:hover,
.dark-mode a.bg-pink-darker:focus {
    background-color: #3f1c28 !important;
}

.dark-mode button.bg-pink-darker:hover,
.dark-mode button.bg-pink-darker:focus {
    background-color: #3f1c28 !important;
}

.dark-mode .bg-pink-darkest {
    background-color: #31161f !important;
}

.dark-mode a.bg-pink-darkest:hover,
.dark-mode a.bg-pink-darkest:focus {
    background-color: #0e0609 !important;
}

.dark-mode button.bg-pink-darkest:hover,
.dark-mode button.bg-pink-darkest:focus {
    background-color: #0e0609 !important;
}

.dark-mode .bg-red-lightest {
    background-color: #fae9e9 !important;
}

.dark-mode a.bg-red-lightest:hover,
.dark-mode a.bg-red-lightest:focus {
    background-color: #f1bfbf !important;
}

.dark-mode button.bg-red-lightest:hover,
.dark-mode button.bg-red-lightest:focus {
    background-color: #f1bfbf !important;
}

.dark-mode .bg-red-lighter {
    background-color: #f0bcbc !important;
}

.dark-mode a.bg-red-lighter:hover,
.dark-mode a.bg-red-lighter:focus {
    background-color: #e79292 !important;
}

.dark-mode button.bg-red-lighter:hover,
.dark-mode button.bg-red-lighter:focus {
    background-color: #e79292 !important;
}

.dark-mode .bg-red-light {
    background-color: #dc6362 !important;
}

.dark-mode a.bg-red-light:hover,
.dark-mode a.bg-red-light:focus {
    background-color: #d33a38 !important;
}

.dark-mode button.bg-red-light:hover,
.dark-mode button.bg-red-light:focus {
    background-color: #d33a38 !important;
}

.dark-mode .bg-red-dark {
    background-color: #a41a19 !important;
}

.dark-mode a.bg-red-dark:hover,
.dark-mode a.bg-red-dark:focus {
    background-color: #781312 !important;
}

.dark-mode button.bg-red-dark:hover,
.dark-mode button.bg-red-dark:focus {
    background-color: #781312 !important;
}

.dark-mode .bg-red-darker {
    background-color: #520d0c !important;
}

.dark-mode a.bg-red-darker:hover,
.dark-mode a.bg-red-darker:focus {
    background-color: #260605 !important;
}

.dark-mode button.bg-red-darker:hover,
.dark-mode button.bg-red-darker:focus {
    background-color: #260605 !important;
}

.dark-mode .bg-red-darkest {
    background-color: #290606 !important;
}

.dark-mode a.bg-red-darkest:hover,
.dark-mode a.bg-red-darkest:focus {
    background-color: black !important;
}

.dark-mode button.bg-red-darkest:hover,
.dark-mode button.bg-red-darkest:focus {
    background-color: black !important;
}

.dark-mode .bg-orange-lightest {
    background-color: #2a2a4a !important;
}

.dark-mode a.bg-orange-lightest:hover,
.dark-mode a.bg-orange-lightest:focus {
    background-color: peachpuff !important;
}

.dark-mode button.bg-orange-lightest:hover,
.dark-mode button.bg-orange-lightest:focus {
    background-color: peachpuff !important;
}

.dark-mode .bg-orange-lighter {
    background-color: #fee0c7 !important;
}

.dark-mode a.bg-orange-lighter:hover,
.dark-mode a.bg-orange-lighter:focus {
    background-color: #fdc495 !important;
}

.dark-mode button.bg-orange-lighter:hover,
.dark-mode button.bg-orange-lighter:focus {
    background-color: #fdc495 !important;
}

.dark-mode .bg-orange-light {
    background-color: #feb67c !important;
}

.dark-mode a.bg-orange-light:hover,
.dark-mode a.bg-orange-light:focus {
    background-color: #fe9a49 !important;
}

.dark-mode button.bg-orange-light:hover,
.dark-mode button.bg-orange-light:focus {
    background-color: #fe9a49 !important;
}

.dark-mode .bg-orange-dark {
    background-color: #ca7836 !important;
}

.dark-mode a.bg-orange-dark:hover,
.dark-mode a.bg-orange-dark:focus {
    background-color: #a2602b !important;
}

.dark-mode button.bg-orange-dark:hover,
.dark-mode button.bg-orange-dark:focus {
    background-color: #a2602b !important;
}

.dark-mode .bg-orange-darker {
    background-color: #653c1b !important;
}

.dark-mode a.bg-orange-darker:hover,
.dark-mode a.bg-orange-darker:focus {
    background-color: #3d2410 !important;
}

.dark-mode button.bg-orange-darker:hover,
.dark-mode button.bg-orange-darker:focus {
    background-color: #3d2410 !important;
}

.dark-mode .bg-orange-darkest {
    background-color: #331e0e !important;
}

.dark-mode a.bg-orange-darkest:hover,
.dark-mode a.bg-orange-darkest:focus {
    background-color: #0b0603 !important;
}

.dark-mode button.bg-orange-darkest:hover,
.dark-mode button.bg-orange-darkest:focus {
    background-color: #0b0603 !important;
}

.dark-mode .bg-yellow-lightest {
    background-color: #fef9e7 !important;
}

.dark-mode a.bg-yellow-lightest:hover,
.dark-mode a.bg-yellow-lightest:focus {
    background-color: #fcedb6 !important;
}

.dark-mode button.bg-yellow-lightest:hover,
.dark-mode button.bg-yellow-lightest:focus {
    background-color: #fcedb6 !important;
}

.dark-mode .bg-yellow-lighter {
    background-color: #fbedb7 !important;
}

.dark-mode a.bg-yellow-lighter:hover,
.dark-mode a.bg-yellow-lighter:focus {
    background-color: #f8e187 !important;
}

.dark-mode button.bg-yellow-lighter:hover,
.dark-mode button.bg-yellow-lighter:focus {
    background-color: #f8e187 !important;
}

.dark-mode .bg-yellow-light {
    background-color: #f5d657 !important;
}

.dark-mode a.bg-yellow-light:hover,
.dark-mode a.bg-yellow-light:focus {
    background-color: #f2ca27 !important;
}

.dark-mode button.bg-yellow-light:hover,
.dark-mode button.bg-yellow-light:focus {
    background-color: #f2ca27 !important;
}

.dark-mode .bg-yellow-dark {
    background-color: #c19d0c !important;
}

.dark-mode a.bg-yellow-dark:hover,
.dark-mode a.bg-yellow-dark:focus {
    background-color: #917609 !important;
}

.dark-mode button.bg-yellow-dark:hover,
.dark-mode button.bg-yellow-dark:focus {
    background-color: #917609 !important;
}

.dark-mode .bg-yellow-darker {
    background-color: #604e06 !important;
}

.dark-mode a.bg-yellow-darker:hover,
.dark-mode a.bg-yellow-darker:focus {
    background-color: #302703 !important;
}

.dark-mode button.bg-yellow-darker:hover,
.dark-mode button.bg-yellow-darker:focus {
    background-color: #302703 !important;
}

.dark-mode .bg-yellow-darkest {
    background-color: #302703 !important;
}

.dark-mode a.bg-yellow-darkest:hover,
.dark-mode a.bg-yellow-darkest:focus {
    background-color: black !important;
}

.dark-mode button.bg-yellow-darkest:hover,
.dark-mode button.bg-yellow-darkest:focus {
    background-color: black !important;
}

.dark-mode .bg-green-lightest {
    background-color: #eff8e6 !important;
}

.dark-mode a.bg-green-lightest:hover,
.dark-mode a.bg-green-lightest:focus {
    background-color: #d6edbe !important;
}

.dark-mode button.bg-green-lightest:hover,
.dark-mode button.bg-green-lightest:focus {
    background-color: #d6edbe !important;
}

.dark-mode .bg-green-lighter {
    background-color: #cfeab3 !important;
}

.dark-mode a.bg-green-lighter:hover,
.dark-mode a.bg-green-lighter:focus {
    background-color: #b6df8b !important;
}

.dark-mode button.bg-green-lighter:hover,
.dark-mode button.bg-green-lighter:focus {
    background-color: #b6df8b !important;
}

.dark-mode .bg-green-light {
    background-color: #8ecf4d !important;
}

.dark-mode a.bg-green-light:hover,
.dark-mode a.bg-green-light:focus {
    background-color: #75b831 !important;
}

.dark-mode button.bg-green-light:hover,
.dark-mode button.bg-green-light:focus {
    background-color: #75b831 !important;
}

.dark-mode .bg-green-dark {
    background-color: #4b9500 !important;
}

.dark-mode a.bg-green-dark:hover,
.dark-mode a.bg-green-dark:focus {
    background-color: #316200 !important;
}

.dark-mode button.bg-green-dark:hover,
.dark-mode button.bg-green-dark:focus {
    background-color: #316200 !important;
}

.dark-mode .bg-green-darker {
    background-color: #264a00 !important;
}

.dark-mode a.bg-green-darker:hover,
.dark-mode a.bg-green-darker:focus {
    background-color: #0c1700 !important;
}

.dark-mode button.bg-green-darker:hover,
.dark-mode button.bg-green-darker:focus {
    background-color: #0c1700 !important;
}

.dark-mode .bg-green-darkest {
    background-color: #132500 !important;
}

.dark-mode a.bg-green-darkest:hover,
.dark-mode a.bg-green-darkest:focus {
    background-color: black !important;
}

.dark-mode button.bg-green-darkest:hover,
.dark-mode button.bg-green-darkest:focus {
    background-color: black !important;
}

.dark-mode .bg-teal-lightest {
    background-color: #eafaf8 !important;
}

.dark-mode a.bg-teal-lightest:hover,
.dark-mode a.bg-teal-lightest:focus {
    background-color: #c1f0ea !important;
}

.dark-mode button.bg-teal-lightest:hover,
.dark-mode button.bg-teal-lightest:focus {
    background-color: #c1f0ea !important;
}

.dark-mode .bg-teal-lighter {
    background-color: #bfefea !important;
}

.dark-mode a.bg-teal-lighter:hover,
.dark-mode a.bg-teal-lighter:focus {
    background-color: #96e5dd !important;
}

.dark-mode button.bg-teal-lighter:hover,
.dark-mode button.bg-teal-lighter:focus {
    background-color: #96e5dd !important;
}

.dark-mode .bg-teal-light {
    background-color: #6bdbcf !important;
}

.dark-mode a.bg-teal-light:hover,
.dark-mode a.bg-teal-light:focus {
    background-color: #42d1c2 !important;
}

.dark-mode button.bg-teal-light:hover,
.dark-mode button.bg-teal-light:focus {
    background-color: #42d1c2 !important;
}

.dark-mode .bg-teal-dark {
    background-color: #22a295 !important;
}

.dark-mode a.bg-teal-dark:hover,
.dark-mode a.bg-teal-dark:focus {
    background-color: #19786e !important;
}

.dark-mode button.bg-teal-dark:hover,
.dark-mode button.bg-teal-dark:focus {
    background-color: #19786e !important;
}

.dark-mode .bg-teal-darker {
    background-color: #11514a !important;
}

.dark-mode a.bg-teal-darker:hover,
.dark-mode a.bg-teal-darker:focus {
    background-color: #082723 !important;
}

.dark-mode button.bg-teal-darker:hover,
.dark-mode button.bg-teal-darker:focus {
    background-color: #082723 !important;
}

.dark-mode .bg-teal-darkest {
    background-color: #092925 !important;
}

.dark-mode a.bg-teal-darkest:hover,
.dark-mode a.bg-teal-darkest:focus {
    background-color: black !important;
}

.dark-mode button.bg-teal-darkest:hover,
.dark-mode button.bg-teal-darkest:focus {
    background-color: black !important;
}

.dark-mode .bg-cyan-lightest {
    background-color: #e8f6f8 !important;
}

.dark-mode a.bg-cyan-lightest:hover,
.dark-mode a.bg-cyan-lightest:focus {
    background-color: #c1e7ec !important;
}

.dark-mode button.bg-cyan-lightest:hover,
.dark-mode button.bg-cyan-lightest:focus {
    background-color: #c1e7ec !important;
}

.dark-mode .bg-cyan-lighter {
    background-color: #b9e3ea !important;
}

.dark-mode a.bg-cyan-lighter:hover,
.dark-mode a.bg-cyan-lighter:focus {
    background-color: #92d3de !important;
}

.dark-mode button.bg-cyan-lighter:hover,
.dark-mode button.bg-cyan-lighter:focus {
    background-color: #92d3de !important;
}

.dark-mode .bg-cyan-light {
    background-color: #5dbecd !important;
}

.dark-mode a.bg-cyan-light:hover,
.dark-mode a.bg-cyan-light:focus {
    background-color: #3aabbd !important;
}

.dark-mode button.bg-cyan-light:hover,
.dark-mode button.bg-cyan-light:focus {
    background-color: #3aabbd !important;
}

.dark-mode .bg-cyan-dark {
    background-color: #128293 !important;
}

.dark-mode a.bg-cyan-dark:hover,
.dark-mode a.bg-cyan-dark:focus {
    background-color: #0c5a66 !important;
}

.dark-mode button.bg-cyan-dark:hover,
.dark-mode button.bg-cyan-dark:focus {
    background-color: #0c5a66 !important;
}

.dark-mode .bg-cyan-darker {
    background-color: #09414a !important;
}

.dark-mode a.bg-cyan-darker:hover,
.dark-mode a.bg-cyan-darker:focus {
    background-color: #03191d !important;
}

.dark-mode button.bg-cyan-darker:hover,
.dark-mode button.bg-cyan-darker:focus {
    background-color: #03191d !important;
}

.dark-mode .bg-cyan-darkest {
    background-color: #052025 !important;
}

.dark-mode a.bg-cyan-darkest:hover,
.dark-mode a.bg-cyan-darkest:focus {
    background-color: black !important;
}

.dark-mode button.bg-cyan-darkest:hover,
.dark-mode button.bg-cyan-darkest:focus {
    background-color: black !important;
}

.dark-mode .bg-white-lightest {
    background-color: white !important;
}

.dark-mode a.bg-white-lightest:hover,
.dark-mode a.bg-white-lightest:focus {
    background-color: #2a2a4a !important;
}

.dark-mode button.bg-white-lightest:hover,
.dark-mode button.bg-white-lightest:focus {
    background-color: #2a2a4a !important;
}

.dark-mode .bg-white-lighter {
    background-color: white !important;
}

.dark-mode a.bg-white-lighter:hover,
.dark-mode a.bg-white-lighter:focus {
    background-color: #2a2a4a !important;
}

.dark-mode button.bg-white-lighter:hover,
.dark-mode button.bg-white-lighter:focus {
    background-color: #2a2a4a !important;
}

.dark-mode .bg-white-light {
    background-color: white !important;
}

.dark-mode a.bg-white-light:hover,
.dark-mode a.bg-white-light:focus {
    background-color: #2a2a4a !important;
}

.dark-mode button.bg-white-light:hover,
.dark-mode button.bg-white-light:focus {
    background-color: #2a2a4a !important;
}

.dark-mode .bg-white-dark {
    background-color: #cccccc !important;
}

.dark-mode a.bg-white-dark:hover,
.dark-mode a.bg-white-dark:focus {
    background-color: #b3b2b2 !important;
}

.dark-mode button.bg-white-dark:hover,
.dark-mode button.bg-white-dark:focus {
    background-color: #b3b2b2 !important;
}

.dark-mode .bg-white-darker {
    background-color: #666666 !important;
}

.dark-mode a.bg-white-darker:hover,
.dark-mode a.bg-white-darker:focus {
    background-color: #4d4c4c !important;
}

.dark-mode button.bg-white-darker:hover,
.dark-mode button.bg-white-darker:focus {
    background-color: #4d4c4c !important;
}

.dark-mode .bg-white-darkest {
    background-color: #333333 !important;
}

.dark-mode a.bg-white-darkest:hover,
.dark-mode a.bg-white-darkest:focus {
    background-color: #1a1919 !important;
}

.dark-mode button.bg-white-darkest:hover,
.dark-mode button.bg-white-darkest:focus {
    background-color: #1a1919 !important;
}

.dark-mode .bg-gray-lightest {
    background-color: #f3f4f5 !important;
}

.dark-mode a.bg-gray-lightest:hover,
.dark-mode a.bg-gray-lightest:focus {
    background-color: #d7dbde !important;
}

.dark-mode button.bg-gray-lightest:hover,
.dark-mode button.bg-gray-lightest:focus {
    background-color: #d7dbde !important;
}

.dark-mode .bg-gray-lighter {
    background-color: #dbdde0 !important;
}

.dark-mode a.bg-gray-lighter:hover,
.dark-mode a.bg-gray-lighter:focus {
    background-color: #c0c3c8 !important;
}

.dark-mode button.bg-gray-lighter:hover,
.dark-mode button.bg-gray-lighter:focus {
    background-color: #c0c3c8 !important;
}

.dark-mode .bg-gray-light {
    background-color: #aab0b6 !important;
}

.dark-mode a.bg-gray-light:hover,
.dark-mode a.bg-gray-light:focus {
    background-color: #8f979e !important;
}

.dark-mode button.bg-gray-light:hover,
.dark-mode button.bg-gray-light:focus {
    background-color: #8f979e !important;
}

.dark-mode .bg-gray-dark {
    background-color: #6b7278 !important;
    background: #343a40 !important;
}

.dark-mode a.bg-gray-dark:hover,
.dark-mode a.bg-gray-dark:focus {
    background-color: #53585d !important;
}

.dark-mode button.bg-gray-dark:hover,
.dark-mode button.bg-gray-dark:focus {
    background-color: #53585d !important;
}

.dark-mode .bg-gray-darker {
    background-color: #36393c !important;
}

.dark-mode a.bg-gray-darker:hover,
.dark-mode a.bg-gray-darker:focus {
    background-color: #1e2021 !important;
}

.dark-mode button.bg-gray-darker:hover,
.dark-mode button.bg-gray-darker:focus {
    background-color: #1e2021 !important;
}

.dark-mode .bg-gray-darkest {
    background-color: #1b1c1e !important;
}

.dark-mode a.bg-gray-darkest:hover,
.dark-mode a.bg-gray-darkest:focus {
    background-color: #030303 !important;
}

.dark-mode button.bg-gray-darkest:hover,
.dark-mode button.bg-gray-darkest:focus {
    background-color: #030303 !important;
}

.dark-mode .bg-gray-dark-lightest {
    background-color: #ebebec !important;
}

.dark-mode a.bg-gray-dark-lightest:hover,
.dark-mode a.bg-gray-dark-lightest:focus {
    background-color: #d1d1d3 !important;
}

.dark-mode button.bg-gray-dark-lightest:hover,
.dark-mode button.bg-gray-dark-lightest:focus {
    background-color: #d1d1d3 !important;
}

.dark-mode .bg-gray-dark-lighter {
    background-color: #c2c4c6 !important;
}

.dark-mode a.bg-gray-dark-lighter:hover,
.dark-mode a.bg-gray-dark-lighter:focus {
    background-color: #a8abad !important;
}

.dark-mode button.bg-gray-dark-lighter:hover,
.dark-mode button.bg-gray-dark-lighter:focus {
    background-color: #a8abad !important;
}

.dark-mode .bg-gray-dark-light {
    background-color: #717579 !important;
}

.dark-mode a.bg-gray-dark-light:hover,
.dark-mode a.bg-gray-dark-light:focus {
    background-color: #585c5f !important;
}

.dark-mode button.bg-gray-dark-light:hover,
.dark-mode button.bg-gray-dark-light:focus {
    background-color: #585c5f !important;
}

.dark-mode .bg-gray-dark-dark {
    background-color: #2a2e33 !important;
}

.dark-mode a.bg-gray-dark-dark:hover,
.dark-mode a.bg-gray-dark-dark:focus {
    background-color: #131517 !important;
}

.dark-mode button.bg-gray-dark-dark:hover,
.dark-mode button.bg-gray-dark-dark:focus {
    background-color: #131517 !important;
}

.dark-mode .bg-gray-dark-darker {
    background-color: #15171a !important;
}

.dark-mode a.bg-gray-dark-darker:hover,
.dark-mode a.bg-gray-dark-darker:focus {
    background-color: black !important;
}

.dark-mode button.bg-gray-dark-darker:hover,
.dark-mode button.bg-gray-dark-darker:focus {
    background-color: black !important;
}

.dark-mode .bg-gray-dark-darkest {
    background-color: #0a0c0d !important;
}

.dark-mode a.bg-gray-dark-darkest:hover,
.dark-mode a.bg-gray-dark-darkest:focus {
    background-color: black !important;
}

.dark-mode button.bg-gray-dark-darkest:hover,
.dark-mode button.bg-gray-dark-darkest:focus {
    background-color: black !important;
}

.dark-mode .bg-azure-lightest {
    background-color: #ecf7fe !important;
}

.dark-mode a.bg-azure-lightest:hover,
.dark-mode a.bg-azure-lightest:focus {
    background-color: #bce3fb !important;
}

.dark-mode button.bg-azure-lightest:hover,
.dark-mode button.bg-azure-lightest:focus {
    background-color: #bce3fb !important;
}

.dark-mode .bg-azure-lighter {
    background-color: #c7e6fb !important;
}

.dark-mode a.bg-azure-lighter:hover,
.dark-mode a.bg-azure-lighter:focus {
    background-color: #97d1f8 !important;
}

.dark-mode button.bg-azure-lighter:hover,
.dark-mode button.bg-azure-lighter:focus {
    background-color: #97d1f8 !important;
}

.dark-mode .bg-azure-light {
    background-color: #7dc4f6 !important;
}

.dark-mode a.bg-azure-light:hover,
.dark-mode a.bg-azure-light:focus {
    background-color: #4daef3 !important;
}

.dark-mode button.bg-azure-light:hover,
.dark-mode button.bg-azure-light:focus {
    background-color: #4daef3 !important;
}

.dark-mode .bg-azure-dark {
    background-color: #3788c2 !important;
}

.dark-mode a.bg-azure-dark:hover,
.dark-mode a.bg-azure-dark:focus {
    background-color: #2c6c9a !important;
}

.dark-mode button.bg-azure-dark:hover,
.dark-mode button.bg-azure-dark:focus {
    background-color: #2c6c9a !important;
}

.dark-mode .bg-azure-darker {
    background-color: #1c4461 !important;
}

.dark-mode a.bg-azure-darker:hover,
.dark-mode a.bg-azure-darker:focus {
    background-color: #112839 !important;
}

.dark-mode button.bg-azure-darker:hover,
.dark-mode button.bg-azure-darker:focus {
    background-color: #112839 !important;
}

.dark-mode .bg-azure-darkest {
    background-color: #0e2230 !important;
}

.dark-mode a.bg-azure-darkest:hover,
.dark-mode a.bg-azure-darkest:focus {
    background-color: #020609 !important;
}

.dark-mode button.bg-azure-darkest:hover,
.dark-mode button.bg-azure-darkest:focus {
    background-color: #020609 !important;
}

.dark-mode .bg-lime-lightest {
    background-color: #f2fbeb !important;
}

.dark-mode a.bg-lime-lightest:hover,
.dark-mode a.bg-lime-lightest:focus {
    background-color: #d6f3c1 !important;
}

.dark-mode button.bg-lime-lightest:hover,
.dark-mode button.bg-lime-lightest:focus {
    background-color: #d6f3c1 !important;
}

.dark-mode .bg-lime-lighter {
    background-color: #d7f2c2 !important;
}

.dark-mode a.bg-lime-lighter:hover,
.dark-mode a.bg-lime-lighter:focus {
    background-color: #bbe998 !important;
}

.dark-mode button.bg-lime-lighter:hover,
.dark-mode button.bg-lime-lighter:focus {
    background-color: #bbe998 !important;
}

.dark-mode .bg-lime-light {
    background-color: #a3e072 !important;
}

.dark-mode a.bg-lime-light:hover,
.dark-mode a.bg-lime-light:focus {
    background-color: #88d748 !important;
}

.dark-mode button.bg-lime-light:hover,
.dark-mode button.bg-lime-light:focus {
    background-color: #88d748 !important;
}

.dark-mode .bg-lime-dark {
    background-color: #62a82a !important;
}

.dark-mode a.bg-lime-dark:hover,
.dark-mode a.bg-lime-dark:focus {
    background-color: #4a7f20 !important;
}

.dark-mode button.bg-lime-dark:hover,
.dark-mode button.bg-lime-dark:focus {
    background-color: #4a7f20 !important;
}

.dark-mode .bg-lime-darker {
    background-color: #315415 !important;
}

.dark-mode a.bg-lime-darker:hover,
.dark-mode a.bg-lime-darker:focus {
    background-color: #192b0b !important;
}

.dark-mode button.bg-lime-darker:hover,
.dark-mode button.bg-lime-darker:focus {
    background-color: #192b0b !important;
}

.dark-mode .bg-lime-darkest {
    background-color: #192a0b !important;
}

.dark-mode a.bg-lime-darkest:hover,
.dark-mode a.bg-lime-darkest:focus {
    background-color: #010200 !important;
}

.dark-mode button.bg-lime-darkest:hover,
.dark-mode button.bg-lime-darkest:focus {
    background-color: #010200 !important;
}

.dark-mode .bg-blue-1 {
    background-color: #0061da;
    color: #fff !important;
}

.dark-mode .bg-blue {
    background: #3223f1;
    color: #fff !important;
}

.dark-mode a.bg-blue:hover,
.dark-mode a.bg-blue:focus {
    background-color: #4032f1 !important;
}

.dark-mode button.bg-blue:hover,
.dark-mode button.bg-blue:focus {
    background-color: #4032f1 !important;
}

.dark-mode .bg-indigo {
    background: #6574cd;
    color: #fff !important;
}

.dark-mode a.bg-indigo:hover,
.dark-mode a.bg-indigo:focus {
    background-color: #3f51c1 !important;
}

.dark-mode button.bg-indigo:hover,
.dark-mode button.bg-indigo:focus {
    background-color: #3f51c1 !important;
}

.dark-mode .bg-purple-gradient {
    background: linear-gradient(to bottom right, #8927ec 0%, #647dee 100%) !important;
    color: #fff !important;
}

.dark-mode a.bg-purple-gradient:hover,
.dark-mode a.bg-purple-gradient:focus {
    background-color: #8c31e4 !important;
}

.dark-mode button.bg-purple-gradient:hover,
.dark-mode button.bg-purple-gradient:focus {
    background-color: #8c31e4 !important;
}

.dark-mode .bg-pink {
    background: #ec82ef !important;
    color: #fff !important;
}

.dark-mode .bg-darkpink {
    background-color: #14a485 !important;
    color: #fff !important;
}

.dark-mode a.bg-pink:hover,
.dark-mode a.bg-pink:focus {
    background-color: #f33d7a !important;
}

.dark-mode button.bg-pink:hover,
.dark-mode button.bg-pink:focus {
    background-color: #f33d7a !important;
}

.dark-mode .bg-red {
    background: #e73827;
    color: #fff !important;
}

.dark-mode a.bg-red:hover,
.dark-mode a.bg-red:focus {
    background-color: #a11918 !important;
}

.dark-mode button.bg-red:hover,
.dark-mode button.bg-red:focus {
    background-color: #a11918 !important;
}

.dark-mode .bg-orange {
    background: #fc7303;
    color: #fff !important;
}

.dark-mode a.bg-orange:hover,
.dark-mode a.bg-orange:focus {
    background-color: #fc7a12 !important;
}

.dark-mode button.bg-orange:hover,
.dark-mode button.bg-orange:focus {
    background-color: #fc7a12 !important;
}

.dark-mode .bg-yellow-1 {
    background-color: #FBB034;
    color: #fff !important;
}

.dark-mode .bg-yellow {
    background: #FBB034;
    color: #fff !important;
}

.dark-mode a.bg-yellow:hover,
.dark-mode a.bg-yellow:focus {
    background-color: #c29d0b !important;
}

.dark-mode button.bg-yellow:hover,
.dark-mode button.bg-yellow:focus {
    background-color: #c29d0b !important;
}

.dark-mode .bg-green-1 {
    background-color: #4ecc48;
    color: #fff !important;
}

.dark-mode .bg-green {
    background: #4ecc48;
    color: #fff !important;
}

.dark-mode a.bg-green:hover,
.dark-mode a.bg-green:focus {
    background-color: #448700 !important;
}

.dark-mode button.bg-green:hover,
.dark-mode button.bg-green:focus {
    background-color: #448700 !important;
}

.dark-mode .bg-teal {
    background: #1caf9f !important;
}

.dark-mode a.bg-teal:hover,
.dark-mode a.bg-teal:focus {
    background-color: #22a193 !important;
}

.dark-mode button.bg-teal:hover,
.dark-mode button.bg-teal:focus {
    background-color: #22a193 !important;
}

.dark-mode .bg-cyan {
    background: #007ea7 !important;
    color: #fff !important;
}

.dark-mode a.bg-cyan:hover,
.dark-mode a.bg-cyan:focus {
    background-color: #117a8b !important;
}

.dark-mode button.bg-cyan:hover,
.dark-mode button.bg-cyan:focus {
    background-color: #117a8b !important;
}

.dark-mode a.bg-white:hover,
.dark-mode a.bg-white:focus {
    background-color: #2a2a4a !important;
}

.dark-mode button.bg-white:hover,
.dark-mode button.bg-white:focus {
    background-color: #2a2a4a !important;
}

.dark-mode .bg-gray {
    background: #5a6970 !important;
}

.dark-mode a.bg-gray:hover,
.dark-mode a.bg-gray:focus {
    background-color: #6c757d !important;
}

.dark-mode button.bg-gray:hover,
.dark-mode button.bg-gray:focus {
    background-color: #6c757d !important;
}

.dark-mode .bg-lightpink-red {
    color: #ff7088 !important;
}

.dark-mode a.bg-gray-dark:hover,
.dark-mode a.bg-gray-dark:focus {
    background-color: #1d2124 !important;
}

.dark-mode button.bg-gray-dark:hover,
.dark-mode button.bg-gray-dark:focus {
    background-color: #1d2124 !important;
}

.dark-mode .bg-azure {
    background: #45aaf2;
}

.dark-mode a.bg-azure:hover,
.dark-mode a.bg-azure:focus {
    background-color: #1594ef !important;
}

.dark-mode button.bg-azure:hover,
.dark-mode button.bg-azure:focus {
    background-color: #1594ef !important;
}

.dark-mode .bg-purple-1 {
    background: #8927ec;
}

.dark-mode .bg-lime {
    background: #7bd235;
}

.dark-mode a.bg-lime:hover,
.dark-mode a.bg-lime:focus {
    background-color: #63ad27 !important;
}

.dark-mode button.bg-lime:hover,
.dark-mode button.bg-lime:focus {
    background-color: #63ad27 !important;
}

.dark-mode .bg-square {
    color: #fff;
    background: #868e96;
}

.dark-mode .bg-primary-light {
    background: #2a2a4a;
}

.dark-mode .bg-google-plus {
    background-color: #dd4b39;
}

.dark-mode .bg-pinterest {
    background: linear-gradient(to right bottom, #c51629 0%, #bd081c 100%);
}

.dark-mode .bg-light-gray {
    background-color: #2a2a4a;
}

.dark-mode .bg-progress-white {
    background-color: #eaeceb;
}

.dark-mode .bg-dribbble {
    background: linear-gradient(to bottom right, #ea4c89, #c93764) !important;
}

.dark-mode .bg-google {
    background: linear-gradient(to bottom right, #e64522, #c33219) !important;
    color: #fff;
}

.dark-mode .bg-success-transparent {
    background-color: rgba(0, 230, 130, 0.1) !important;
}

.dark-mode .bg-info-transparent {
    background-color: #123d68 !important;
}

.dark-mode .bg-warning-transparent {
    background-color: rgba(255, 162, 43, 0.1) !important;
}

.dark-mode .bg-danger-transparent {
    background-color: rgba(255, 56, 43, 0.1) !important;
}

.dark-mode .bg-pink-transparent {
    background-color: rgba(213, 109, 252, 0.1) !important;
}

.dark-mode .bg-purple-transparent {
    background-color: rgba(96, 77, 216, 0.1) !important;
}

.dark-mode .bg-dark-transparent {
    background-color: rgba(0, 0, 0, 0.15) !important;
}

.dark-mode .bg-white-transparent {
    background-color: rgba(255, 255, 255, 0.2) !important;
}

.dark-mode .bg-secondary-transparent {
    background-color: rgba(5, 195, 251, 0.1) !important;
}

.dark-mode .shadow-primary {
    box-shadow: 0 7px 30px var(--primary03) !important;
}

.dark-mode .shadow-secondary {
    box-shadow: 0 7px 30px rgba(130, 207, 242, 0.1) !important;
}

.dark-mode .shadow-warning {
    box-shadow: 0 7px 30px rgba(251, 176, 52, 0.1) !important;
}

.dark-mode .shadow-info {
    box-shadow: 0 7px 30px rgba(40, 146, 235, 0.5) !important;
}

.dark-mode .shadow-success {
    box-shadow: 0 7px 30px rgba(26, 122, 16, 0.1) !important;
}

.dark-mode .shadow-danger {
    box-shadow: 0 7px 30px rgba(245, 167, 184, 0.1) !important;
}

.dark-mode .google-plus1 {
    background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
}

.dark-mode .pinterest1 {
    background: linear-gradient(to right bottom, #bd081c 0%, #eb4553 100%);
}

.dark-mode .bg-default {
    background: #e9e9f1;
    color: #282f53;
}

.dark-mode .border {
    border-color: rgba(255, 255, 255, 0.1) !important;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .border-top {
    border-top-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .border-end {
    border-right-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .border-bottom {
    border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .border-start {
    border-left-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .border-secondary {
    border: 1px solid #05c3fb !important;
}

.dark-mode .border-success {
    border-color: #1bbfa7 !important;
}

.dark-mode .border-info {
    border-color: #45aaf2 !important;
}

.dark-mode .border-warning {
    border-color: #ecb403 !important;
}

.dark-mode .border-danger {
    border-color: #f82649 !important;
}

.dark-mode .border-pink {
    border-color: #ec82ef !important;
}

.dark-mode .border-orange {
    border-color: #fc7303 !important;
}

.dark-mode .border-light {
    border-color: #f8f9fa !important;
}

.dark-mode .border-transparent {
    border-color: #656a71 !important;
}

.dark-mode .border-dark {
    border-color: #343a40 !important;
}

.dark-mode .border-white {
    border-color: #fff !important;
}

.dark-mode .border-end-1 {
    border-right-color: #d5dce3;
}

.dark-mode .text-white-transparent,
.dark-mode .text-white-transparent-1 {
    color: #fff !important;
}

.dark-mode a.text-primary:hover,
.dark-mode a.text-primary:focus {
    color: var(--primary-bg-color) !important;
}

.dark-mode .text-secondary {
    color: #05c3fb !important;
}

.dark-mode a.text-secondary:hover,
.dark-mode a.text-secondary:focus {
    color: #05c3fb !important;
}

.dark-mode .text-success {
    color: #09ad95 !important;
}

.dark-mode a.text-success:hover,
.dark-mode a.text-success:focus {
    color: #448700 !important;
}

.dark-mode .text-info {
    color: #1170e4 !important;
}

.dark-mode a.text-info:hover,
.dark-mode a.text-info:focus {
    color: #1594ef !important;
}

.dark-mode .text-warning {
    color: #f7b731 !important;
}

.dark-mode a.text-warning:hover,
.dark-mode a.text-warning:focus {
    color: #c29d0b !important;
}

.dark-mode .text-danger {
    color: #e82646 !important;
}

.dark-mode a.text-danger:hover,
.dark-mode a.text-danger:focus {
    color: #d22827 !important;
}

.dark-mode .text-light {
    color: #ebedef !important;
}

.dark-mode a.text-light:hover,
.dark-mode a.text-light:focus {
    color: #dae0e5 !important;
}

.dark-mode .text-dark {
    color: #dedefd !important;
}

.dark-mode a.text-dark:hover,
.dark-mode a.text-dark:focus {
    color: var(--primary-bg-color) !important;
}

.dark-mode .text-body {
    color: #495057 !important;
}

.dark-mode .text-muted {
    color: #9595b5 !important;
}

.dark-mode .text-black-50 {
    color: rgba(0, 0, 0, 0.5) !important;
}

.dark-mode .text-white-50 {
    color: rgba(255, 255, 255, 0.5) !important;
}

.dark-mode .text-hide {
    color: transparent;
    background-color: transparent;
}

.dark-mode .heading-inverse {
    background-color: #333;
    color: #fff;
}

.dark-mode .heading-success {
    background-color: #1643a3;
    color: #fff;
}

.dark-mode .heading-info {
    background-color: #45aaf2;
    color: #fff;
}

.dark-mode .heading-warning {
    background-color: #ecb403;
    color: #fff;
}

.dark-mode .heading-danger {
    background-color: #c21a1a;
    color: #fff;
}

.dark-mode .text-inherit {
    color: inherit !important;
}

.dark-mode .text-default {
    color: #7a7a92 !important;
}

.dark-mode .text-muted-dark {
    color: #1c232f !important;
}

.dark-mode .text-fb-blue {
    color: #234684 !important;
}

.dark-mode .text-blue {
    color: #467fcf !important;
}

.dark-mode .text-indigo {
    color: #6574cd !important;
}

.dark-mode .text-purple {
    color: #867efc !important;
}

.dark-mode .text-lightpink-red {
    color: #ff7088 !important;
}

.dark-mode .text-lightgreen {
    color: #26eda2 !important;
}

.dark-mode .text-pink {
    color: #ec82ef !important;
}

.dark-mode .text-red {
    color: #c21a1a !important;
}

.dark-mode .text-orange {
    color: #fc7303 !important;
}

.dark-mode .text-yellow {
    color: #ecb403 !important;
}

.dark-mode .text-green {
    color: #4ecc48 !important;
}

.dark-mode .text-green-1 {
    color: #0dff01 !important;
}

.dark-mode .text-teal {
    color: #2bcbba !important;
}

.dark-mode .text-cyan {
    color: #17a2b8 !important;
}

.dark-mode .text-white {
    color: #dedefd !important;
}

.dark-mode .text-gray {
    color: #969696 !important;
}

.dark-mode .text-gray-dark {
    color: #343a40 !important;
}

.dark-mode .text-azure {
    color: #45aaf2 !important;
}

.dark-mode .text-lime {
    color: #7bd235 !important;
}

.dark-mode .text-transparent {
    color: #332525;
}

.dark-mode .text-facebook {
    color: #3b5998;
}

.dark-mode .text-google-plus {
    color: #dd4b39;
}

.dark-mode .text-twitter {
    color: #1da1f2;
}

.dark-mode .text-pinterest {
    color: #bd081c;
}

.dark-mode .text-secondary-gradient {
    background: linear-gradient(to bottom right, #82cff2 0%, #28b7f9 100%);
    -webkit-text-fill-color: transparent;
    background: linear-gradient(to right, #cd489c 0%, #ce4ba4 100%);
    -webkit-text-fill-color: transparent;
}

.dark-mode .text-info-gradient {
    background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
    -webkit-text-fill-color: transparent;
}

.dark-mode .text-success-gradient {
    background: linear-gradient(to bottom right, #1ea38f 0%, #5cf9e2 100%);
    -webkit-text-fill-color: transparent;
}

.dark-mode .text-facebook-gradient {
    background: linear-gradient(to right bottom, #1e3c72 0%, #3d6cbf 100%);
    -webkit-text-fill-color: transparent;
}

.dark-mode .text-twitter-gradient {
    background: linear-gradient(to right bottom, #1e63c3 0%, #00f2fe 100%);
    -webkit-text-fill-color: transparent;
}

.dark-mode .text-google-plus-gradient {
    background: linear-gradient(to right bottom, #dd4b39 0%, #ef6a5a 100%);
    -webkit-text-fill-color: transparent;
}

.dark-mode .text-success-shadow {
    text-shadow: 0 5px 10px rgba(19, 191, 166, 0.3) !important;
}

.dark-mode .text-info-shadow {
    text-shadow: 0 5px 10px rgba(7, 116, 248, 0.3) !important;
}

.dark-mode .text-warning-shadow {
    text-shadow: 0 5px 10px rgba(255, 162, 43, 0.3) !important;
}

.dark-mode .text-danger-shadow {
    text-shadow: 0 5px 10px rgba(255, 56, 43, 0.3) !important;
}

.dark-mode .text-pink-shadow {
    text-shadow: 0 5px 10px rgba(213, 109, 252, 0.3) !important;
}

.dark-mode .text-purple-shadow {
    text-shadow: 0 5px 10px rgba(96, 77, 216, 0.3) !important;
}

.dark-mode .text-dark-shadow {
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.3) !important;
}

.dark-mode .text-white-shadow {
    text-shadow: 0 5px 10px rgba(255, 255, 255, 0.3) !important;
}

.dark-mode .text-secondary-shadow {
    text-shadow: 0 5px 10px rgba(130, 207, 242, 0.3);
}

.dark-mode .alert-success hr {
    border-top-color: #09ad95;
}

.dark-mode .alert-info hr {
    border-top-color: #1170e4;
}

.dark-mode .alert-warning hr {
    border-top-color: #f7b731;
}

.dark-mode .alert-danger hr {
    border-top-color: #e82646;
}

.dark-mode.horizontal .main-sidemenu .slide-left,
.dark-mode.horizontal .main-sidemenu .slide-right {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode.horizontal .main-sidemenu .slide-left svg,
.dark-mode.horizontal .main-sidemenu .slide-right svg {
    fill: #dedefd;
}

.dark-mode.horizontal .logo-horizontal .header-brand-img.light-logo1 {
    display: none;
}

.dark-mode.horizontal .side-menu>li>a {
    color: #dedefd;
}

.dark-mode .sidebar {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 8px 14.72px 1.28px rgba(42, 38, 53, 0.5);
}

.dark-mode .light-layout {
    display: block;
}

.dark-mode .dark-layout {
    display: none;
}

.dark-mode .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #30304d;
}

.dark-mode .select2-container--default .select2-results>.select2-results__options {
    box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
}

.dark-mode .select2-dropdown {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .side-header .header-brand-img.desktop-logo {
    display: block !important;
}

.dark-mode .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.dark-mode.app.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo1 {
    display: none !important;
}

.dark-mode .header-brand .header-brand-img.logo-3 {
    display: none;
}

.dark-mode .header-brand .header-brand-img.logo {
    display: block;
}

.dark-mode.sidebar-mini .app-header .light-logo1 {
    display: none !important;
}

.dark-mode.sidenav-toggled .header-brand-img.light-logo {
    display: none !important;
}

.dark-mode.light-menu.sidenav-toggled .header-brand-img.light-logo {
    display: block !important;
}

.dark-mode.light-menu.sidenav-toggled-open .header-brand-img.light-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: block !important;
}

.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.hover-submenu.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
}

.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.desktop-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.light-logo {
    display: none !important;
}

.dark-mode.sidebar-mini.hover-submenu1.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block !important;
}

.dark-mode.light-menu.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
}

.dark-mode.sidebar-mini.sidenav-toggled .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: block;
}

.dark-mode.sidebar-mini.sidenav-toggled.sidenav-toggled-open .app-sidebar .side-header .header-brand-img.toggle-logo {
    display: none;
}

@media (max-width: 991px) {
    .dark-mode .app-header.header .header-brand-img.desktop-logo {
        display: block;
    }
}

.dark-mode #external-events {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .fc-theme-standard td,
.dark-mode .fc-theme-standard th {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .fc-theme-standard .fc-scrollgrid {
    border: 0px solid rgba(255, 255, 255, 0.1);
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    border-left: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .fc .fc-daygrid-day-number {
    color: #dedefd;
}

.dark-mode .fc .fc-daygrid-day.fc-day-today {
    background-color: #272742;
}

.dark-mode .fc-theme-standard .fc-list {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .fc-theme-standard .fc-list-day-cushion {
    background: #22223d;
}

.dark-mode .fc .fc-list-event:hover td {
    background: #22223d;
}

.dark-mode .fc-list-event.fc-event {
    color: #dedefd !important;
}

.dark-mode .fc-direction-ltr .fc-list-day-text,
.dark-mode .fc-direction-rtl .fc-list-day-side-text {
    color: #dedefd;
}

.dark-mode .fc-direction-ltr .fc-list-day-side-text,
.dark-mode .fc-direction-rtl .fc-list-day-text {
    color: #dedefd;
}

.dark-mode .sweet-alert {
    background: #2a2a4a;
}

.dark-mode .sweet-alert h2 {
    color: #dedefd;
}

.dark-mode .sweet-alert P {
    color: #9595b5;
}

.dark-mode .alert-default {
    background-color: #1a1a3c;
}

.dark-mode .irs-line-mid,
.dark-mode .irs-line-right,
.dark-mode .irs-line-left {
    background-color: #1a1a3c;
}

.dark-mode .irs-from,
.dark-mode .irs-to,
.dark-mode .irs-single {
    background: #1a1a3c;
}

.dark-mode .irs-from,
.dark-mode .irs-to,
.dark-mode .irs-single {
    color: #dedefd;
}

.dark-mode .irs-min,
.dark-mode .irs-max {
    color: #dedefd;
    background: #1a1a3c;
}

.dark-mode .irs-grid-text {
    color: #9595b5;
}

.dark-mode .irs-modern .irs-slider,
.dark-mode .irs-outline .irs-slider {
    background-color: #1a1a3c;
}

.dark-mode .tree li {
    color: #dedefd;
}

.dark-mode .tree li a {
    color: #dedefd;
}

.dark-mode .tree li.branch li {
    background: #2a2a4a;
}

.dark-mode .tree ul:before {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tree ul li:before {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .dTree a {
    color: #dedefd;
}

.dark-mode .tab_wrapper .content_wrapper {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper.right_side .content_wrapper {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper.right_side>ul li {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper.right_side>ul li.active {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper.right_side>ul li.active::before {
    background: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper.right_side>ul li:after {
    background: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper>ul {
    border-bottom-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .tab_wrapper>ul li {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .tab_wrapper>ul li.active:after {
    background: transparent;
}

.dark-mode .dropify-wrapper {
    color: #dedefd;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .dropify-wrapper .dropify-preview {
    background-color: #2a2a4a;
}

.dark-mode .dropify-wrapper .dropify-message span.file-icon {
    color: #9595b5;
}

.dark-mode .dropify-wrapper:hover {
    background-image: linear-gradient(-45deg, #1a1a3c 25%, transparent 25%, transparent 50%, #1a1a3c 50%, #1a1a3c 75%, transparent 75%, transparent);
}

.dark-mode .ff_fileupload_wrap .ff_fileupload_dropzone:focus,
.dark-mode .ff_fileupload_wrap .ff_fileupload_dropzone:active {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ff_fileupload_wrap .ff_fileupload_dropzone {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
}

.dark-mode .ff_fileupload_wrap .ff_fileupload_dropzone:hover {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .sp-replacer {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
    color: #fff;
}

.dark-mode .sp-replacer:hover,
.dark-mode .sp-replacer.sp-active {
    border-color: rgba(255, 255, 255, 0.1);
    color: #fff;
}

.dark-mode .sp-container {
    background: #2a2a4a;
    box-shadow: 0 10px 40px 0 rgba(34, 34, 61, 0.8);
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .sp-picker-container {
    border-left: 0;
}

.dark-mode .ui-timepicker-wrapper {
    background: #23223a;
    border-color: rgba(255, 255, 255, 0.07);
    box-shadow: 0 16px 18px 0 #0e0f2e;
}

.dark-mode .ui-timepicker-list li {
    color: rgba(255, 255, 255, 0.8);
}

.dark-mode .datepicker .datepicker-switch,
.dark-mode .datepicker td,
.dark-mode .datepicker th {
    color: #dedefd !important;
}

.dark-mode .datepicker-dropdown.datepicker-orient-top:after,
.dark-mode .datepicker-dropdown.datepicker-orient-top:before {
    border-top-color: #30304d;
}

.dark-mode .SumoSelect>.CaptionCont {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
    color: #9595b5;
}

.dark-mode .SumoSelect.disabled>.CaptionCont {
    border-color: rgba(255, 255, 255, 0.1);
    background: #22223c;
    border-radius: 0;
}

.dark-mode .SumoSelect.open>.optWrapper {
    background: #2a2a4a;
}

.dark-mode .SumoSelect>.optWrapper {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .SumoSelect>.optWrapper>.options li.opt {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .SumoSelect>.optWrapper>.options li.opt:hover {
    background-color: #262641;
}

.dark-mode .SumoSelect.open .search-txt {
    background: #2a2a4a;
    color: #9595b5;
}

.dark-mode .SumoSelect .select-all {
    background-color: #2a2a4a;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .SumoSelect>.optWrapper>.MultiControls {
    border-top: 1px solid rgba(255, 255, 255, 0.12);
    background-color: #2a2a4a;
}

.dark-mode .SumoSelect>.optWrapper.multiple>.MultiControls>p:hover {
    background-color: #393958;
}

.dark-mode datepicker-dropdown.datepicker-orient-top:after,
.dark-mode datepicker-dropdown.datepicker-orient-top:before {
    border-top: 7px solid #3e3e50;
}

.dark-mode datepicker-dropdown:after,
.dark-mode datepicker-dropdown:before {
    border-bottom-color: #2e2e4a;
}

.dark-mode .datepicker table tr td span:hover,
.dark-mode .datepicker table tr td span.focused {
    background: #21213c !important;
    color: #dedefd !important;
}

.dark-mode .datepicker .prev,
.dark-mode .datepicker .next {
    background: #39395c;
}

.dark-mode .datepicker-dropdown:after {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .datepicker-dropdown::before {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ms-choice {
    color: #9595b5;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ms-choice.disabled {
    background-color: #2d2d48;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ms-drop {
    color: #9595b5;
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ms-drop.bottom {
    box-shadow: 0px 8px 14.72px 1.28px rgba(34, 34, 61, 0.8);
}

.dark-mode .select2-dropdown {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .select2-container--default .select2-results__option[aria-selected=true] {
    background-color: #30304d;
}

.dark-mode .select2-container--default.select2-container--disabled .select2-selection--single {
    background-color: #2d2d48;
}

.dark-mode .ms-search input {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .transfer-double {
    background-color: #2a2a4a;
}

.dark-mode .transfer-double-content-tabs {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .transfer-double-content-tabs .tab-item-name.tab-active {
    background: #252336;
}

.dark-mode .transfer-double-content-left,
.dark-mode .transfer-double-content-right {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .transfer-double-list-footer {
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .transfer-double-list-search-input {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
}

.dark-mode .transfer-double-list-search-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.dark-mode .transfer-double-list-main::-webkit-scrollbar-track {
    background-color: #2a2a4a;
}

.dark-mode .checkbox-group label:before {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .transfer-double-content-param {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .transfer-double-selected-list-search-input {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
}

.dark-mode .transfer-double-selected-list-search-input::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.dark-mode .multi-wrapper .search-input::placeholder {
    color: rgba(255, 255, 255, 0.8);
}

.dark-mode .btn-select-arrow {
    color: #dedefd;
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .multi-wrapper {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .multi-wrapper .search-input {
    border-bottom-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .multi-wrapper .item-1 {
    color: #dedefd;
    background: #2a2a4a;
}

.dark-mode .multi-wrapper .selected-wrapper,
.dark-mode .multi-wrapper .item-group-1 .group-label {
    background: #1a1a3c;
}

.dark-mode .multi-wrapper .non-selected-wrapper {
    background: #2a2a4a;
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .iti input {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .iti input[type=tel],
.dark-mode .iti input[type=text] {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
    border-right-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .iti__selected-flag {
    border-right-color: rgba(255, 255, 255, 0.1);
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .iti--allow-dropdown .iti__flag-container:hover .iti__selected-flag {
    background-color: #2a2a4a;
    border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .iti__country-list {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .iti__country.iti__highlight {
    background-color: #2a2a4a;
}

.dark-mode .iti__divider {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .note-editor.note-frame.panel.panel-default .panel-heading {
    background-color: #2a2a4a;
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .note-btn.btn-default {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .note-editor.note-airframe,
.dark-mode .note-editor.note-frame {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .note-editor .btn-default:not(:disabled):not(.disabled):active {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .form-control-file::-webkit-file-upload-button {
    background-color: #1a1a3c;
    color: #dedefd;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .note-editor.note-airframe .note-editing-area .note-codable,
.dark-mode .note-editor.note-frame .note-editing-area .note-codable {
    background-color: #1a1a3c;
    color: rgba(255, 255, 255, 0.7);
}

.dark-mode .richText .richText-editor {
    background-color: #2a2a4a;
    border-left-color: rgba(255, 255, 255, 0);
}

.dark-mode .richText .richText-toolbar ul {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .richText .richText-toolbar ul li a {
    color: #dedefd;
}

.dark-mode .richText .richText-toolbar ul li a:hover {
    background-color: #2a2a4a;
}

.dark-mode .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .richText .richText-toolbar ul li a .richText-dropdown-outer .richText-dropdown .richText-dropdown-close {
    background: #2a2a4a;
    color: #9595b5;
}

.dark-mode .richText .richText-form input[type=text],
.dark-mode .richText .richText-form input[type=file],
.dark-mode .richText .richText-form input[type=number] {
    border-color: rgba(255, 255, 255, 0.1);
    background: #1b1b2d;
    color: #dedefd;
}

.dark-mode .richText .richText-form select {
    border-color: rgba(255, 255, 255, 0.1);
    background: #1b1b2d;
    color: #dedefd;
}

.dark-mode .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .richText .richText-toolbar ul li a .richText-dropdown-outer ul.richText-dropdown li a:hover {
    background: #1b1b2d;
}

.dark-mode .richText .richText-undo,
.dark-mode .richText .richText-redo {
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ql-toolbar.ql-snow {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ql-toolbar.ql-snow .ql-picker-label {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
    color: #dedefd;
}

.dark-mode .ql-container.ql-snow {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ql-snow.ql-toolbar button,
.dark-mode .ql-snow .ql-toolbar button {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
    color: #dedefd;
}

.dark-mode .ql-snow.ql-toolbar button:last-child,
.dark-mode .ql-snow .ql-toolbar button:last-child {
    border-right-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    background-color: #2a2a4a;
}

.dark-mode .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .ql-snow .ql-formats {
    color: #dedefd;
}

.dark-mode .ql-snow .ql-tooltip {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 0px 5px #000;
    color: #dedefd;
    margin-left: 100px;
}

.dark-mode .ql-tooltip.ql-editing input[type=text],
.dark-mode .ql-tooltip.ql-editing input[type=file],
.dark-mode .ql-tooltip.ql-editing input[type=number] {
    border-color: rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
    color: #dedefd;
}

.dark-mode .ql-bubble .ql-tooltip {
    background-color: #2a2a4a;
}

.dark-mode .sw-theme-dots>ul.step-anchor {
    background: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .sw-theme-dots>ul.step-anchor:before {
    background-color: #3e3e63;
}

.dark-mode .sw-theme-dots>ul.step-anchor>li>a:before {
    background: #3e3e63;
}

.dark-mode .sw-theme-dots .step-content {
    background-color: #2a2a4a;
}

.dark-mode .sw-theme-dots .sw-toolbar {
    background: #2a2a4a;
}

.dark-mode .wizard {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #2a2a4a;
    border-radius: 3px;
}

.dark-mode .wizard>.content {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .wizard>.content>.title {
    color: #dedefd;
}

.dark-mode .wizard>.actions .disabled a {
    background-color: #404062;
    color: rgba(255, 255, 255, 0.62);
}

.dark-mode .wizard>.steps .current a .number,
.dark-mode .wizard>.steps .current a:active .number,
.dark-mode .wizard>.steps .current a:hover .number {
    background-color: var(--primary-bg-color);
}

.dark-mode .wizard>.steps a .number,
.dark-mode .wizard>.steps a:active .number,
.dark-mode .wizard>.steps a:hover .number {
    background-color: #404062;
}

.dark-mode .wizard.vertical>.content,
.dark-mode .wizard.vertical>.actions {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .wizard>.steps .done a .number,
.dark-mode .wizard>.steps .done a:active .number,
.dark-mode .wizard>.steps .done a:hover .number {
    background-color: #0dcd94;
}

.dark-mode .wizard>.steps a .number,
.dark-mode .wizard>.steps a:active .number,
.dark-mode .wizard>.steps a:hover .number {
    background-color: #404062;
}

.dark-mode .owl-nav button {
    background: #5b5b83 !important;
    border-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .flot-text {
    color: #9595b5 !important;
}

.dark-mode tspan {
    fill: #9595b5 !important;
}

.dark-mode .nvd3 text {
    fill: #9595b5;
}

.dark-mode .nvd3 .nv-axis line {
    stroke: rgba(119, 119, 142, 0.2);
}

.dark-mode .nvd3 .nv-discretebar .nv-groups text,
.dark-mode .nvd3 .nv-multibarHorizontal .nv-groups text {
    fill: #dedefd;
}

.dark-mode .countdown li {
    background: #2a2a4a;
    border: 5px solid rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

@media (min-width: 992px) {

    .dark-mode.horizontal .horizontal-main .slide .slide-menu,
    .dark-mode.horizontal .horizontal-main .slide .sub-slide-menu,
    .dark-mode.horizontal .horizontal-main .slide .sub-slide-menu2 {
        background-color: #2a2a4a;
        border-color: rgba(255, 255, 255, 0.1);
        box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.3) !important;
    }
}

.rtl.dark-mode .app-sidebar {
    border-left-color: rgba(255, 255, 255, 0.1);
    border-right: inherit;
}

.rtl.dark-mode .side-header {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.rtl.dark-mode .vtimeline .timeline-wrapper .timeline-panel:after {
    border-right-color: rgba(255, 255, 255, 0.1);
    border-left-color: rgba(255, 255, 255, 0.1);
}

.rtl.dark-mode .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-right-color: rgba(255, 255, 255, 0.2) !important;
    border-left-color: rgba(255, 255, 255, 0.2) !important;
    right: -10px !important;
    left: auto;
}

.rtl.dark-mode .notification .notification-body:before {
    border-left-color: #2a2a4a;
    border-right-color: transparent;
}

.rtl.dark-mode .border-end {
    border-left-color: rgba(255, 255, 255, 0.1) !important;
    border-right-color: transparent !important;
}

.rtl.dark-mode .card-group .card.border-end {
    border-left: 1px solid rgba(255, 255, 255, 0.1) !important;
    border-right: inherit !important;
}

.rtl.dark-mode .tab_wrapper.right_side .content_wrapper {
    border-right-color: rgba(255, 255, 255, 0.1) !important;
}

.rtl.dark-mode .tab_wrapper.right_side>ul li {
    border-right-color: rgba(255, 255, 255, 0.1) !important;
}

.rtl.dark-mode .vtimeline .timeline-wrapper.timeline-inverted .timeline-panel:after {
    border-right-width: 0;
    border-left-width: 10px;
}

.rtl.dark-mode .richText .richText-toolbar ul li a {
    border-left: rgba(156, 162, 161, 0.2) solid 1px;
    border-right: inherit;
}

.rtl.dark-mode .tree ul:before {
    border-right: 1px dotted rgba(255, 255, 255, 0.3);
}

.rtl.dark-mode .main-content-body-chat .border-start-0 {
    border-left-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .dataTables_wrapper .selected {
    background: #1a1a3c;
}

.dark-mode .table .table-primary th {
    color: #7373dd;
}

.dark-mode .fc-theme-standard .fc-popover {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #2a2a4a;
}

.dark-mode .fc-theme-standard .fc-popover-header {
    background: #1e2448;
}

.dark-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_summary .ff_fileupload_filename input {
    background: transparent;
    color: #fff;
}

.dark-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_preview .ff_fileupload_preview_image {
    background-color: #1e2448;
}

.dark-mode .ff_fileupload_wrap table.ff_fileupload_uploads td.ff_fileupload_actions button {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: #1e2448;
}

.dark-mode .dropify-wrapper .dropify-preview .dropify-render img {
    background-color: transparent;
}

.dark-mode.rtl .table.border-dashed thead th:last-child {
    border-left-color: transparent;
}

.dark-mode.rtl .table thead th:last-child {
    border-left-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .handle-counter input {
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .card-group .card.border-end {
    border-right: 1px solid rgba(255, 255, 255, 0.1) !important;
}

.dark-mode div.dt-autofill-list {
    background-color: #1e2448;
}

.dark-mode div.dt-autofill-list div.dt-autofill-question {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode div.dt-autofill-list div.dt-autofill-button {
    border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark-mode div.dt-autofill-list ul li:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.dark-mode .datetimetable input {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: transparent;
    color: #dedefd;
}

.dark-mode .datetimetable input::placeholder {
    color: #dedefd;
}

.dark-mode div.dt-datetime {
    background-color: #2a2a4a;
    border-color: rgba(255, 255, 255, 0.1);
    box-shadow: 0px 16px 18px rgba(0, 0, 0, 0.2);
}

.dark-mode div.dt-datetime table td {
    color: rgba(255, 255, 255, 0.8);
}

.dark-mode div.dt-datetime table th {
    color: #dedefd;
}

.dark-mode div.dt-datetime div.dt-datetime-iconLeft button,
.dark-mode div.dt-datetime div.dt-datetime-iconRight button,
.dark-mode div.dt-datetime div.dt-datetime-iconUp button,
.dark-mode div.dt-datetime div.dt-datetime-iconDown button {
    background: rgba(255, 255, 255, 0.1);
}

.dark-mode div.dt-datetime div.dt-datetime-label:hover {
    border-color: rgba(255, 255, 255, 0.1);
    background-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .pricing-tabs ul.nav-price {
    border: 1px solid rgba(255, 255, 255, 0.1);
    background: #1e2448;
}

.dark-mode .pricing-tabs ul.nav-price li a {
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
}

.dark-mode .pricing-tabs ul.nav-price li a.active {
    background: var(--primary-bg-color);
}

.dark-mode .select2-container--default .select2-selection--single .select2-selection__arrow b {
    border-color: rgba(255, 255, 255, 0.6) transparent transparent transparent;
}

.dark-mode .select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
    border-color: transparent transparent rgba(255, 255, 255, 0.6) transparent;
}

.dark-mode .fc .fc-list-empty {
    background-color: transparent;
}

.dark-mode .c3 path,
.dark-mode .c3 line {
    stroke: rgba(255, 255, 255, 0.1);
}

.dark-mode .form-floating>.form-control::placeholder {
    color: transparent !important;
}

.dark-mode .is-loading .form-control-plaintext::placeholder {
    color: #a9a9c1;
}

.dark-mode .is-loading .form-control-plaintext:focus-visible {
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .is-loading select option:checked {
    background-color: rgba(255, 255, 255, 0.1);
    color: #dedefd;
}

.dark-mode .list-group-item a {
    color: rgba(255, 255, 255, 0.7);
}
